import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import Breadcrumb from 'Components/Common/Breadcrumb';
import BasicTable from 'Components/dashboard/dashboard';
import ModalMess from 'Components/Message Modal';
import { getProfile } from 'assistance/promoterClient_service';

const LeadDashboard = () => {
  document.title = " Skartio - Insider";
  const [show, setShow] = useState(false);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await getProfile();

        if (response.data && response.status === 200) {
          console.log(response.data?.data?.phone);
          sessionStorage.setItem('PHONE',response.data?.data?.phone)

          // setProfileDat(response.data?.data);
        } else if (response.data && response.status === 203) {
          setShow(true)


        }


      } catch (error) {
        console.error('Failed to fetch profile data', error);
      }
    };

    fetchProfile();
  }, []);

  return (
    <React.Fragment>

      
{show && (
        <ModalMess   
        button1Text='Continue'
        button2Text='Cancel & Logout'
        isOpen={show}
        toggle={() => setShow(false)} // Update toggle function
  //       onButton1Click={handleButton1Click}
  // onButton2Click={handleButton2Click}
          
        />
      )} 
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Own Lead" breadcrumbItem="Own Lead" />
        

                     <BasicTable/> 
        </Container>
      </div>
    </React.Fragment >
  );
}

export default LeadDashboard;
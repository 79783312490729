import { values } from "lodash";
import { promoter_baseUrl } from "./const";
import {
  deletePromoterService,
  deleteService,
  getService,
  PostImage,
  postPromoter,
  postPromoterService,
  postService,
  putKYC,
  putService,
} from "./http_service";
const PromoterBaseUrl = promoter_baseUrl;

export default function createPromoterClient(
  firstName: string,
  state: string,
  country: string,
  email: string,
  phone: string
) {
  let createAccountData = {
    firstName: firstName,
    lastName: "",
    gender: "",
    city: "",
    state: state,
    country: country,
    email: email,
    emailVerified: "Y",
    phone: phone,
    phoneVerified: "N",
    pincode: "",
  };

  return postPromoterService(
    PromoterBaseUrl + "/user",
    createAccountData,
    "createUserProfile"
  );
}

async function getApplicant() {
  return getService(PromoterBaseUrl + "/applicant", "getApplicant");
}

// get details of promoter

async function getProfile() {
  return getService(PromoterBaseUrl + "promoter", "getPromoter");
}

async function getDocType() {
  return getService(
    PromoterBaseUrl + "/settings/static/identitydocumenttypes",
    "getIdentityDocumentTypes"
  );
}
async function getRewardList( first,last) {
  return getService(
    PromoterBaseUrl + `/promoter/rewards?pageNum=${first}&pageSize=${last}&sortOrder=desc&keyword=&trnStatus=&claimStatus=&rewardType=`,
    "getAllPromoterReward"
  );
}

async function updatesProfile(
firstName: string, lastName: string, // emailval: string,
// emailVerified: string,
// phoneVerified: string,

pincode: string, // gender: string,
country: string, 
state: string, 

email?: string) {
  let createAccountData = {
    firstName: firstName,
    lastName: lastName,

    email: email,
    // emailVerified: emailVerified,
    // phoneVerified: phoneVerified,
    pincode: pincode,
    // gender: gender,
    country: country,
    state: state,
  };
  return postPromoter(
    PromoterBaseUrl + "promoter",
    createAccountData,
    "updatePromoter"
  );
}




async function settlementRequest(
  status: string,
  method: string,
  methodName: string,
  Type: string,
  RefNumber: string,
  RefId: string,
  IFSCCode: string,
  IsPrimary: boolean,
  seq:number,
  reqValue: string,
  totalBalance: string,
  availableBalance: string,

) {
  let createAccountData = {
    paymentMethod: {
      status: status,
      method: method,
      methodName: methodName,
      Type: Type,
      RefNumber: RefNumber,
      RefId: RefId,
      IFSCCode: IFSCCode,
      SWIFTCode: IsPrimary,
      IsPrimary: IsPrimary,
      seq:seq
    },
    reqValue: reqValue,
    totalBalance: totalBalance,
    availableBalance: availableBalance,
    note:""
  };
  return postPromoterService(
    PromoterBaseUrl + "promoter/settlement/request",
    createAccountData,
    "createSettlementRequest"
  );
}


async function addKyc(
  name: string,
  gender: string,
  DOB: Date,
  permanentPin: string,
  IdType: string,
  IdNumber: string
) {
  let createAccountData = {
    name: name,
    gender: gender,
    DOB: DOB,
    permanentPin: permanentPin,
    IdType: IdType,
    IdNumber: IdNumber,
    address: [],
  };
  return putKYC(
    PromoterBaseUrl + "promoter/kyc",
    createAccountData,
    "updatePromoterKYC"
  );
}

async function kycDoc(document: File) {
  // debugger
  const formData = new FormData();
  formData.append('file', document);
  //   let createAccountData = {
  //   document: formData,
  // };
  return PostImage(
    PromoterBaseUrl + "promoter/kyc/document",
    formData,
    "updatePromoterKYCDocument"
  );
}

async function addLeadGen(
  Phone: string,
  leadName: string,
  pincode: string,
  pocName: string,
  pocEmail: any,
  countryCode: string,
  countryName: string
) {
  // debugger
  let createAccountData = {
    channelID: "C1",
    channelName: "PromoterApp",
    programId: "P1",
    programName: "Promoter Program",
    leadCategoryId: "08",
    leadCategoryName: "Product Afiliation",
    leadUniqueIdType: "P",
    leadUniqueID: Phone,
    leadName: leadName,
    pincode: pincode,
    origin: "",
    additionalInfo: {
      pocName: pocName,
      pocPhone: "",
      pocEmail: pocEmail,
      address: {
        line1: "",
        line2: "",
        countryCode: countryCode,
        countryName: countryName,
      },
    },
  };
  return postPromoterService(
    PromoterBaseUrl + "promoter/leadgen",
    createAccountData,
    "createPromoterLeadGen"
  );
}

// async function getAllLeadGen(catId:string) {
//   return getService( PromoterBaseUrl+ "promoter/leadgen?pageNum=1&pageSize=5&sortOrder=desc&keyword=&category="+catId,
//      "getAllPromoterLead");
// }

async function getAllLeadGen(
  catId: string,
  leadStatus: string,
  searchValue: string
) {
  return getService(
    PromoterBaseUrl +
      "promoter/leadgen?pageNum=1&pageSize=10&sortOrder=desc&keyword=" +
      searchValue +
      "&category=" +
      catId +
      "&leadStatus=" +
      leadStatus,
    "getAllPromoterLead"
  );
}

async function getAction() {
  return getService(PromoterBaseUrl + "settings", "getPromoterSettings");
}

async function getTranscationSummary() {
  return getService(PromoterBaseUrl + "promoter/transactionsummary", "getAllPromoterTranscationLogSummary");
}

async function getTransaction(first,last) {
  return getService(
    PromoterBaseUrl + `promoter/transaction?pageNum=${first}&pageSize=${last}&sortOrder=desc&method=&status=&type=`,
     "getAllPromoterTranscationLog");
}




async function updateLeadStatus(
  leadId: string,
  leadStatusDesc: string,
  statusId: string
) {
  // debugger
  let createAccountData = {
    leadStatus: statusId,
    leadStatusDesc: leadStatusDesc,
  };
  return postPromoter(   
    PromoterBaseUrl + "promoterleads/" + leadId + "/status",
    createAccountData,
    "updatePromoterLeadStatusAdmin"
  );
}

async function addpaymethod(
  method: any,
  methodName: any,
  Type: any,
  RefNumber: any,
  RefId: any,
  IFSCCode: any,
) {
  // debugger
  let createAccountData = {
    status: "A",
    method: method,
    methodName: methodName,
    Type: Type,
    RefNumber: RefNumber,
    RefId: RefId,
    IFSCCode: IFSCCode,
    SWIFTCode: "",
  };
  return postPromoterService(
    PromoterBaseUrl + "/promoter/paymethod",
    createAccountData,
    "createPromoterPaymentMethod"
  );
}


async function getPromoterPaymentmethod(first,last) {
  return getService(PromoterBaseUrl +`promoter/paymethod?pageNum=${first}&pageSize=${last}&sortOrder=desc&method=&status`,"getAllPromoterPaymentMethod");
}
async function getPromoterWallet() {
  return getService(PromoterBaseUrl + "/promoter/wallet","getPromoterWallet");
}

async function deletePromoterPaymentmethod(seqId:string) {
  return deletePromoterService(PromoterBaseUrl + `promoter/paymethod/${seqId}`,"deletePromoterPaymentMethod");
}

async function setPrimaryAccount(seqId:string) {
  let createAccountData = {
    status: 0,
   
  };

  return postPromoter(PromoterBaseUrl + `/promoter/paymethod/${seqId}/setasprimary`,createAccountData,"setDefaultPromoterPaymentMethod");
}


async function requestOTP( identityType: string, type: string) {
  let createAccountData = {
    account: {
      identityType: identityType,
      type: type,
    },
  };
  return putService(
    PromoterBaseUrl + "/user/requestotp",
    createAccountData,
    "requestOTPVerification"
  );
}


async function VerifyOTP(value: string, identityType: string, type: string) {
  let createAccountData = {
    account: {
      otp:value,
      identityType: identityType,
      type: type,
    },
  };
  return putService(
    PromoterBaseUrl + "/user/verifyotp",
    createAccountData,
    "verifyOTPVerification"
  );
}



async function UpdateOTP(value: string, identityType: string, type: string) {
  let createAccountData = {
    account: {
      value: value,
      identityType: identityType,
      type: type,
    },
  };
  return putService(
    PromoterBaseUrl + "user/identity/otp",
    createAccountData,
    "requestOTP"
  );
}

async function UpdateData(value: string, identityType: string, type: string,otp: string,) {
  let createAccountData = {
    account: {
      value: value,
      identityType: identityType,
      type: type,
     otp: otp,

    },
  };
  return putService(
    PromoterBaseUrl + "user/identity",
    createAccountData,
    "updateIdentity"
  );
}



export {
  getApplicant,
  getProfile,
  updatesProfile,
  addKyc,
  getDocType,
  kycDoc,
  addLeadGen,
  getAllLeadGen,
  getAction,
  updateLeadStatus,
  getPromoterPaymentmethod,
  addpaymethod,
  deletePromoterPaymentmethod,
  setPrimaryAccount,
  getRewardList,
  getTranscationSummary,
  getTransaction,
  getPromoterWallet,
  settlementRequest,
  requestOTP,
  UpdateOTP,
  VerifyOTP,
  UpdateData
};

// import React, { useEffect, useState } from "react";
// import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, Label } from "reactstrap";
// import styles from './login-otp-forgot.module.scss';
// import { setCookie, setToken } from "assistance/user_service";
// import { forgetPassword, loginUser } from "assistance/identity_service";
// import { toast, ToastContainer } from "react-toastify";
// import 'react-toastify/dist/ReactToastify.css';
// import createPromoterClient, { getProfile } from "assistance/promoterClient_service";

// interface OTPModalProps {
//   isOpen: boolean;
//   toggle: () => void;
//   formData: any;
//   em:string;
// }

// const LoginOTPModal: React.FC<OTPModalProps> = ({ isOpen, toggle, formData,em }) => {
//   const [otp, setOtp] = useState(["", "", "", ""]);
//   const [otpError, setOtpError] = useState(false);  const [show, setShow] = useState(false);




//   const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
//     const value = e.target.value;
//     if (/^[0-9]?$/.test(value)) { // Allow only single digits
//       const newOtp = [...otp];
//       newOtp[index] = value;
//       setOtp(newOtp);

//       // Move to the next input field or go back on delete
//       if (value && index < otp.length - 1) {
//         (document.getElementById(`otp-input-${index + 1}`) as HTMLInputElement)?.focus();
//       } else if (!value && index > 0) {
//         (document.getElementById(`otp-input-${index - 1}`) as HTMLInputElement)?.focus();
//       }
//     }
//   };


//   const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
//     const pasteData = e.clipboardData.getData("Text").trim();
    
//     if (/^\d{4}$/.test(pasteData)) { // Check if the pasted data is exactly 4 digits
//       const newOtp = pasteData.split("").slice(0, 4); // Split into an array, ensure only 4 digits
//       setOtp(newOtp);
//       setOtpError(false);
//       e.preventDefault(); // Prevent the default paste action
  
//       // Automatically move focus to the last input field
//       setTimeout(() => {
//         const lastInput = document.getElementById(`otp-input-3`) as HTMLInputElement;
//         lastInput?.focus();
//       }, 0);
//     }
//   };

//   const submitForm = async (event: React.FormEvent<HTMLButtonElement>) => {
//     debugger
//     event.preventDefault();
  
//     if (otp.includes("")) {
//       setOtpError(true);
//       return;
//     } else {
//       setOtpError(false);
//     }
  
//     // Join the otp array into a single string
//     const otpString = otp.join("");
//   const emailv=sessionStorage.getItem('emailID')
//  if (emailv && emailv !== "Enter Valid Email Address" && emailv !== "") {
//       forgetPassword(emailv, "", "EMAIL",otpString, "U3")
//         .then((response) => {
//           if (response.status === 200) {
//           } else {
//             setTimeout(() => {
//               toast.error("Please Try again later");
//             }, 1000);
//           }
//         })
//         .catch((error) => {
//           console.error("Error in forgetPassword:", error);
//           // setShowPasswordOTP(false);
//           toast.error("Error while requesting OTP");
//         });
//     }
//   };
//   const handleKeyDown = (e) => {
//     if (e.key === 'Enter') {
//       e.preventDefault(); // Prevent default behavior to avoid unintended submissions
//       submitForm(e);
//     }
//   };

 


//   return (
//     <>
//                       <ToastContainer/>

   
//     <Modal isOpen={isOpen} toggle={toggle} className={`${styles.modal}`} onKeyDown={handleKeyDown}>
//       <ModalHeader toggle={toggle}>OTP Verification</ModalHeader>
//       <ModalBody>
//       <Label className="form-label">Enter OTP send to Your Email {" "}{em} </Label>
//         <div className={styles["otp-inputs"]}>
//           {otp.map((digit, index) => (
//             <div className="m-2" key={index}>
//               <Input
//                 type="text"
//                 id={`otp-input-${index}`}
//                  autoComplete="off"
//                 maxLength={1}
//                 value={digit}
//                 onChange={(e) => handleChange(e, index)}
//                 onPaste={index === 0 ? handlePaste : undefined}

//                 className={`${styles["otp-input"]} ${otpError ? styles.invalid : ''}`}
//               />
//             </div>
//           ))}
//         </div>
//         <div className="mb-3 mt-4">
//                           <Label className="form-label">New Password</Label>
//                           <div className="input-group auth-pass-inputgroup">
//                             <Input
//                               name="password"
//                               // value={validation.values.password || ""}
//                               type={show ? "text" : "password"}
//                               placeholder="Enter Password"
//                               autoComplete="off"
//                               // onChange={validation.handleChange}
//                               // onBlur={validation.handleBlur}
//                               // invalid={
//                               //   validation.touched.password &&
//                               //   Boolean(validation.errors.password)
//                               // }
//                             />
//                             <button
//                               onClick={() => setShow(!show)}
//                               className="btn btn-light"
//                               type="button"
//                               id="password-addon"
//                             >
//                               <i className="mdi mdi-eye-outline"></i>
//                             </button>
//                          </div>
//                          </div>
//                          <div className="mb-3 mt-4">
//                           <Label className="form-label">Confirm Password</Label>
//                           <div className="input-group auth-pass-inputgroup">
//                             <Input
//                               name="password"
//                               // value={validation.values.password || ""}
//                               type={show ? "text" : "password"}
//                               placeholder="Enter Password"
//                               autoComplete="off"
//                               // onChange={validation.handleChange}
//                               // onBlur={validation.handleBlur}
//                               // invalid={
//                               //   validation.touched.password &&
//                               //   Boolean(validation.errors.password)
//                               // }
//                             />
//                             <button
//                               onClick={() => setShow(!show)}
//                               className="btn btn-light"
//                               type="button"
//                               id="password-addon"
//                             >
//                               <i className="mdi mdi-eye-outline"></i>
//                             </button>
//                          </div>
//                          </div>       
                       
//         {/* {otpError && <p className={styles.errorText}>Please enter the full OTP.</p>} */}
//       </ModalBody>
//       <ModalFooter>
   
//         <Button color="primary" onClick={submitForm}  
//       >Update Password</Button>
//       </ModalFooter>
//     </Modal>
//     </>
//   );
// };

// export default LoginOTPModal;



import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, Label } from "reactstrap";
import styles from './login-otp-forgot.module.scss';
import { setCookie, setToken } from "assistance/user_service";
import { forgetPassword, loginUser } from "assistance/identity_service";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import createPromoterClient, { getProfile } from "assistance/promoterClient_service";

interface OTPModalProps {
  isOpen: boolean;
  toggle: () => void;
  formData: any;
  em: string;
}

const LoginOTPModal: React.FC<OTPModalProps> = ({ isOpen, toggle, formData, em }) => {
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [otpError, setOtpError] = useState(false);
  const [show, setShow] = useState(false);
  const [shows, setShows] = useState(false);

  const [password, setPassword] = useState(""); // State for new password
  const [confirmPassword, setConfirmPassword] = useState(""); // State for confirm password
  const [passwordError, setPasswordError] = useState(""); // Error for password mismatch

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const value = e.target.value;
    if (/^[0-9]?$/.test(value)) { // Allow only single digits
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value && index < otp.length - 1) {
        (document.getElementById(`otp-input-${index + 1}`) as HTMLInputElement)?.focus();
      } else if (!value && index > 0) {
        (document.getElementById(`otp-input-${index - 1}`) as HTMLInputElement)?.focus();
      }
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pasteData = e.clipboardData.getData("Text").trim();
    if (/^\d{4}$/.test(pasteData)) { // Check if the pasted data is exactly 4 digits
      const newOtp = pasteData.split("").slice(0, 4);
      setOtp(newOtp);
      setOtpError(false);
      e.preventDefault();
      setTimeout(() => {
        const lastInput = document.getElementById(`otp-input-3`) as HTMLInputElement;
        lastInput?.focus();
      }, 0);
    }
  };

  const validatePasswords = () => {
    if (password !== confirmPassword) {
      setPasswordError("Passwords do not match.");
      return false;
    }
    setPasswordError("");
    return true;
  };

  const submitForm = async (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (otp.includes("")) {
      setOtpError(true);
      return;
    }
    if (!validatePasswords()) return;

    const otpString = otp.join("");
    const emailv = sessionStorage.getItem('emailID');
    if (emailv && emailv !== "Enter Valid Email Address" && emailv !== "") {
      forgetPassword(emailv, btoa(password), "EMAIL", otpString, "U3")
        .then((response) => {
          if (response.status === 200) {
            toast.success("Password updated successfully.");
            toggle(); // Close modal after success
          } else {
            toast.error("Please try again later.");
          }
        })
        .catch((error) => {
          console.error("Error in forgetPassword:", error);
          toast.error("Error while requesting OTP");
        });
    }
  };

  return (
    <>
      <ToastContainer />
      <Modal isOpen={isOpen} toggle={toggle} className={`${styles.modal}`}>
        <ModalHeader toggle={toggle}>OTP Verification</ModalHeader>
        <ModalBody>
          <Label className="form-label">Enter OTP sent to your email {em}</Label>
          <div className={styles["otp-inputs"]}>
            {otp.map((digit, index) => (
              <div className="m-2" key={index}>
                <Input
                  type="text"
                  id={`otp-input-${index}`}
                  autoComplete="off"
                  maxLength={1}
                  value={digit}
                  onChange={(e) => handleChange(e, index)}
                  onPaste={index === 0 ? handlePaste : undefined}
                  className={`${styles["otp-input"]} ${otpError ? styles.invalid : ''}`}
                />
              </div>
            ))}
          </div>
          {otpError && <p className={styles.errorText}>Please enter valid OTP.</p>}

          <div className="mb-3 mt-4">
            <Label className="form-label">New Password</Label>
            <div className="input-group auth-pass-inputgroup">
              <Input
                name="password"
                type={show ? "text" : "password"}
                placeholder="Enter Password"
                autoComplete="off"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  // Validate passwords on input change
                  if (e.target.value !== confirmPassword) {
                    setPasswordError("Passwords do not match.");
                  } else {
                    setPasswordError("");
                  }
                }}
              />
              <button
                onClick={() => setShow(!show)}
                className="btn btn-light"
                type="button"
                id="password-addon"
              >
                <i className="mdi mdi-eye-outline"></i>
              </button>
            </div>
          </div>

          <div className="mb-3 mt-4">
            <Label className="form-label">Confirm Password</Label>
            <div className="input-group auth-pass-inputgroup">
              <Input
                name="confirmPassword"
                type={shows ? "text" : "password"}
                placeholder="Enter Confirm Password"
                autoComplete="off"
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                  // Validate passwords on input change
                  if (password !== e.target.value) {
                    setPasswordError("Passwords do not match.");
                  } else {
                    setPasswordError("");
                  }
                }}
                onCopy={(e) => e.preventDefault()}
                onPaste={(e) => e.preventDefault()}
              />
              <button
                onClick={() => setShows(!shows)}
                className="btn btn-light"
                type="button"
                id="password-addon"
              >
                <i className="mdi mdi-eye-outline"></i>
              </button>
            </div>
          </div>
          {passwordError && <p className={styles.errorText}>{passwordError}</p>}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={submitForm}>
            Update Password
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default LoginOTPModal;

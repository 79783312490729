import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  Row,
  Toast,
  ToastHeader,
  ToastBody,
} from "reactstrap";
import styles from './manage-profle.module.scss'
import { useFormik } from "formik";
import * as Yup from "yup";
import Breadcrumbs from "../../Components/Common/Breadcrumb";
import KYCApplication from "pages/kyc/kyc";
import { getProfile, requestOTP, updatesProfile } from "assistance/promoterClient_service";
import { fetchCountryList } from "pages/Authentication/Registerfunctions";
import { getPinCode } from "assistance/static_api";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Agreement from "Components/agreement/agreement";
import ModalMess from "Components/Message Modal";
import VerifyEmail from "./login-otp-Phone/verify-email";
import EmailModal from "./email-modal/email-modal";
import VerifyEmailOTP from "./email-modal/verify-email";

interface Country {
  name: string;
  phoneCode: string;
  sortname: string;
}




interface FormData {
  firstname: string;
  lastname: string;
  country: string;
  countryCode:string;
  state: string;
  pinCode: string;
  email:string;
}

interface Errors {
  firstname?: string;
  lastname?: string;
  country?: string;
  state?: string;
  pinCode?: string;
  email?:string;
}

const ManageProfile: React.FC = () => {
  const [countryCodeVal, setCountryCodeVal] = useState("");
  const [codeVal, setCodeVal] = useState("");
  const [toast2, setToast2] = useState<boolean>(false);
  const [countryList, setCountryList] = useState<Country[]>([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [sortName, setSortName] = useState("");
  const [profileData, setProfileData] = useState("");
  const [stateName, setStateName] = useState("");
  const [show, setShow] = useState(false);
  const[data,setdata]=useState(false)
  // Meta title
  const [isEditing, setIsEditing] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [showOTp, setShowOTp] = useState(false);

  const [showOTPs, setShowOTPs] = useState(false);
;
  const toggleEdit = () => {
    setIsEditing(!isEditing);
  };

  // console.log(countryList);

  const updates = (data: any) => {
    getProfile().then((response) => {
      if (response.status === 200) {
        
        // console.log(response?.data?.data);
        setProfileData(response?.data?.data);
        
        
  
        const countryName = response?.data?.data?.country ? response?.data?.data?.country : 'India' ;
        
        // Find the matching country in the countryList array
        const matchingCountry = data.find((country: { name: any; }) => country.name === countryName);
        // console.log(matchingCountry)
        // console.log(countryList)
        
        if (matchingCountry) {
          setFormData({
            firstname: response?.data?.data?.firstName,
            lastname: response?.data?.data?.lastName,
            country: countryName,
            countryCode: matchingCountry.sortname,
            state: response?.data?.data?.state,
            pinCode: response?.data?.data?.pincode,
            email:response?.data?.data?.email,
          });
        } else {
          setFormData({
            firstname: response?.data?.data?.firstName,
            lastname: response?.data?.data?.lastName,
            country: countryName,
            countryCode: "", 
            state: response?.data?.data?.state,
            pinCode: response?.data?.data?.pincode,
            email:response?.data?.data?.email,
          });
        }
        

        setStateName(response?.data?.data?.state)
      }else if (response.data && response.status === 203) {
        setShow(true)

        // window.location.href="/createAccount";
     }

    });
  };

  useEffect(() => {
  const fetchProfile = async () => {
    try {
      const response = await getProfile();

      if (response.data && response.status === 200) {
       
        setProfileData(response.data?.data);
        setFormData((prevData) => ({
          ...prevData, // Keep all existing fields
          email: response?.data?.data?.email, // Update only the email field
        }));
        
      } else if (response.data && response.status === 203) {
        setShow(true)


      }


    } catch (error) {
      console.error('Failed to fetch profile data', error);
    }
  };
  fetchProfile()
  },[data])

  useEffect(() => {
    // getProfile().then((response) => {
    //   if (response.status === 200) {
    //     console.log(response?.data?.data);
    //   }
    // });

    const getCountries = async () => {
      try {
        const countries = await fetchCountryList(); // Call the function from JS file
        setCountryList(countries);
        if(countries){
          updates(countries);
        }
        // console.log(countries);
      } catch (err) {
        console.error("Error fetching countries:", err);
      }
    };
    getCountries();


  }, []);

  const [formData, setFormData] = useState<FormData>({
    firstname: "",
    lastname: "",
    country: "",
    countryCode:"",
    state: "",
    pinCode: "",
    email:"",
  });

  const [errors, setErrors] = useState<Errors>({}); // State to hold validation errors
  const handleChanges = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCountry = e.target.value;
    const [countryName, countryCode] = selectedCountry.split(",");    
    setSelectedCountry(countryName);

    setFormData((prevData) => ({
      ...prevData,
      country: countryName, // Update the country property
      countryCode: countryCode,

    }));
    setSortName(countryCode);
  };

  // console.log(formData.country, formData.countryCode)


  const handleCountryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCountry = e.target.value;
    const [countryName, countryCode] = selectedCountry.split(",");
    const country = countryList.find((c) => c.name === selectedCountry);
    setCountryCodeVal(country?.phoneCode.toString() || ""); 
    // Update form data with selected country

    setFormData((prevData) => ({
      ...prevData,
      country: countryName,
      countryCode: countryCode,
    }));
    // setFormData((prevData) => ({
    //   ...prevData,
    //   country: `${countryName},${countryCode}`,
    //   countrycode: countryCode,
    // }));
    


  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // Clear errors for the current field
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const validate = () => {
    const newErrors: Errors = {};
    if (!formData.firstname) {
      newErrors.firstname = "First name is required";
    }
    if (!formData.lastname) {
      newErrors.lastname = "Last name is required";
    }
    if (!formData.country) {
      newErrors.country = "Country is required";
    }
    if (!stateName) {
      newErrors.state = "state is required";
    }
    if (!formData.pinCode) {
      newErrors.pinCode = "Pin code is required";
    } else if (!/^\d{6}$/.test(formData.pinCode)) {
      newErrors.pinCode = "Pin code must be 5 digits";
    }

    setErrors(newErrors); // Update errors state
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };
  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (validate()) {

      const { firstname, lastname, pinCode, email } = formData;
      const payload = {
        firstName: firstname,
        lastName: lastname,
        pincode: pinCode,
        country: selectedCountry,
        state: stateName,
        email:email,
      };

      try {
        // Assuming `updatesProfile` requires 5 separate arguments
        const response = await updatesProfile(
          payload.firstName,
          payload.lastName,
          payload.pincode,
          payload.country,
          payload.state,
          payload.email,
        

        );

        // Check if response contains data
        if (response.data && response.status === 200) {
          
          updates(countryList);
          toast.success("Profile updated successfully!", { autoClose: 2000 });
          // console.log(response.data.data[0]?.statename); // Log the fetched state name
           
   

          // Show the toast message (implement this as needed)
        } else if (response.status === 203) {
          // Handle specific status code (203) scenario here
          console.warn(
            "Received status 203: Possible warning or different condition."
          );
        } else {
          // Handle other unexpected statuses
          console.error("Unexpected response status:", response.status);
        }
      } catch (err) {
        console.error("Error fetching countries:", err);
      }
    }
  };

  useEffect(() => {
    const fetchPinCodeData = async () => {
      try {
        const response = await getPinCode(formData?.pinCode, sortName); 

        if (response.data && response.status === 200) {
          setStateName(response.data.data[0]?.statename); 
        } else if (response.status === 203) {
          setToast2(true);

          console.error("No data found or status is not 200:", response);
          setTimeout(() => {
            setToast2(false);
          }, 2000);
        }
      } catch (err) {
        console.error("Error fetching countries:", err);
      }
    };

    fetchPinCodeData();
  }, [formData?.pinCode]);


  const emailChange = async (email: string) => {


      const { firstname, lastname, pinCode,  } = formData;
      const payload = {
       
        identityType:"EMAIL",
        type: "BUSINESSUSER",
        
      };

      try {
        // Assuming `updatesProfile` requires 5 separate arguments
        const response = await requestOTP(
        
          payload.identityType,
          payload.type,
        
        

        );

        // Check if response contains data
        if (response.status === 200) {
          
       
          toast.success(" OTP will send to your email!", { autoClose: 2000 });
          // console.log(response.data.data[0]?.statename); // Log the fetched state name
           
          setShowOTPs(true)

          // Show the toast message (implement this as needed)
        } else if (response.status === 203) {
          // Handle specific status code (203) scenario here
          console.warn(
            "Received status 203: Possible warning or different condition."
          );
        } else {
          // Handle other unexpected statuses
          console.error("Unexpected response status:", response.status);
        }
      } catch (err) {
        console.error("Error fetching countries:", err);
      }
    
  };
  
  return (
    <React.Fragment>
      <ToastContainer/>

    {show && (
        <ModalMess   
        button1Text='Continue'
        button2Text='Cancel & Logout'
        isOpen={show}
        toggle={() => setShow(false)} // Update toggle function
  //       onButton1Click={handleButton1Click}
  // onButton2Click={handleButton2Click}
          
        />
      )}

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Manage Profile" breadcrumbItem="Manage Profile" />
   
          <Row>
            <Col sm={12} md={12} lg={12} xl={12} xxl={6}>
            <Card>
      <CardBody>
        {/* <h4 className="card-title mb-4">Basic Details</h4> */}
        {!isEditing ? (
          // Display details
          <div className={`${styles.profileDisplay} p-4`}>
          <h4 className={`${styles.cardTitle} text-center text-uppercase mb-4`}>Profile Details</h4>
          <div className="row g-3">
            <div className="col-md-6  ">
              <div className={styles.profileItem}>
                
                <i className="fas fa-user"></i> <strong>First Name</strong>
                <span>{formData.firstname || "N/A"}</span>
              </div>
            </div>
            <div className="col-md-6">
              <div className={styles.profileItem}>
                <i className="fas fa-user"></i> <strong>Last Name</strong>
                <span>{formData.lastname || "N/A"}</span>
              </div>
            </div>
            <div className="col-md-6">
              <div className={styles.profileItem}>
                <i className="fas fa-globe"></i> <strong>Country</strong>
                <span>{formData.country || "N/A"}</span>
              </div>
            </div>
            <div className="col-md-6">
              <div className={styles.profileItem}>
                <i className="fas fa-map-marker-alt"></i> <strong>State</strong>
                <span>{stateName || "N/A"}</span>
              </div>
            </div>
            <div className="col-md-6">
              <div className={styles.profileItem}>
                <i className="fas fa-map-pin"></i> <strong>Pin Code</strong>
                <span>{formData.pinCode || "N/A"}</span>
              </div>
            </div>
            <div className="col-md-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6">
              <div className={styles.profileItem}>
                <i className="fas fa-envelope"></i> <strong>Email</strong>
                <span>{formData.email || "N/A"}{" "}{" "}<a onClick={()=>emailChange(formData.email)}><i className="fas fa-pen" style={{fontSize:"12px"}}></i></a> </span>      
              </div>
       
            </div>
          </div>
          <div className="text-center mt-4">
            <button
              // className={styles.editButton}
                  style={{
                                      backgroundColor: "#642e5c",
                                      color: "white",
                                      border: `2px solid #642e5c`,
                                      padding:"10px",
                                      borderRadius:"5px"
                                    }}
              onClick={toggleEdit}
            >
              Edit Profile
            </button>
          </div>
        </div>
        
        
        
        ) : (
          // Display editable form
          <Form className="needs-validation">
            <Row>
              <Col md="6">
                <FormGroup className="mb-3">
                  <Label htmlFor="validationCustom01">First Name</Label>
                  <Input
                    name="firstname"
                    autoComplete="off"
                    placeholder="First name"
                    type="text"
                    onChange={handleChange}
                    value={formData.firstname}
                    invalid={!!errors.firstname} // Show error if exists
                  />
                  <FormFeedback>{errors.firstname}</FormFeedback>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup className="mb-3">
                  <Label htmlFor="validationCustom02">Last Name</Label>
                  <Input
                    name="lastname"
                    autoComplete="off"
                    placeholder="Last name"
                    type="text"
                    onChange={handleChange}
                    value={formData.lastname}
                    invalid={!!errors.lastname}
                  />
                  <FormFeedback>{errors.lastname}</FormFeedback>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md="4" >
                <FormGroup className="mb-3">
                  <Label htmlFor="validationCustom04">Country</Label>
                  <select
                    name="country"
                    className={`form-select ${errors.country ? "is-invalid" : ""}`}
                    onChange={handleChange}
                    value={`${formData.country},${formData.countryCode}`}
                  >
                    <option value="">Select Country</option>
                    {countryList.map((country, index) => (
                      <option key={index} value={`${country.name},${country.sortname}`}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                  <FormFeedback>{errors.country}</FormFeedback>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup className="mb-3">
                  <Label htmlFor="validationCustom05">Pin Code</Label>
                  <Input
                    name="pinCode"
                    autoComplete="off"
                    placeholder="Pin Code"
                    type="text"
                    onChange={handleChange}
                    value={formData.pinCode}
                    invalid={!!errors.pinCode}
                  />
                  <FormFeedback>{errors.pinCode}</FormFeedback>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup className="mb-3">
                  <Label htmlFor="validationCustom03">State</Label>
                  <Input
                    name="state"
                    placeholder="State"
                    type="text"
                    onChange={handleChange}
                    value={stateName}
                    invalid={!!errors.state}
                  />
                  <FormFeedback>{errors.state}</FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            {/* <Row>
              <Col md="6">
                <FormGroup className="mb-3">
                  <Label htmlFor="validationCustom04">Email</Label>
                  <Input
                    name="email"
                    autoComplete="off"
                    placeholder="Enter Email"
                    type="email"
                    onChange={handleChange}
                    value={formData.email}
                    invalid={!!errors.email}
                  />
                  <FormFeedback>{errors.email}</FormFeedback>
                </FormGroup>
              </Col>
            </Row> */}
            <Button
              type="button"
              style={{ backgroundColor: "#642e5c", marginRight: "10px" }}
              onClick={handleSubmit}
            >
              Update Profile
            </Button>
            <Button
              type="button"
              style={{ backgroundColor: "#ccc", color: "#000" }}
              onClick={toggleEdit}
            >
              Cancel
            </Button>
          </Form>
        )}
      </CardBody>
    </Card>
            </Col>
            <Col sm={12} md={12} lg={12} xl={6} xxl={3}>
              <KYCApplication   profileData={profileData}            setdata={setdata}
              />
            </Col>
            <Col sm={12} md={12} lg={12} xl={6} xxl={3}>
              <Agreement />
            </Col>
          </Row>
        </Container>
        {showOTPs &&(
<VerifyEmail
isOpen={showOTPs}
toggle={() => setShowOTPs(false)} // Update toggle function
formData={formData}
toggles={() => setShowOTP(true)}
/>
)}
 {showOTP &&(
<EmailModal
            isOpen={showOTP}
            toggle={() => setShowOTP(false)} // Update toggle function
            toggles={() => setShowOTp(true)} 
          
           />
)}



{showOTp &&(
<VerifyEmailOTP
            isOpen={showOTp}
            toggle={() => setShowOTp(false)} // Update toggle function
            setdata={setdata} 
            data={data}    
           />
)}
      </div>
    </React.Fragment>
  );
};

export default ManageProfile;

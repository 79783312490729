import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Col, Container, Input, Modal, ModalHeader, Row, ModalBody, CardTitle, InputGroup, Nav, NavItem, NavLink } from 'reactstrap';
import Activity from './Activity';
// import MonthlyEarning from './MonthlyEarning';
// import SocialSource from './SocialSource';
// import TopCities from './TopCities';
import WelComeback from './WelComeback';
// import LatestTransaction from "./LatestTransaction";
import classNames from "classnames";

// import StackedColumnChart from "./StackedColumnChart";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from 'reselect';

import { getChartData as onGetChartData } from '../Dashboard/thunk';

import Breadcrumb from 'Components/Common/Breadcrumb';
import { DashboardEmailItem, Report } from './type';
// import StackedColumnChart from './StackedColumnChart';
import SocialSource from './SocialSource';
import MonthlyEarning from './MonthlyEarning';
import TopCities from './TopCities';
import LatestTransaction from './LatestTransaction';
import { isAuthenticated, removeToken } from 'assistance/user_service';
import StackedColumnChart from './StackedColumnChart';
import DialogModal from 'Components/Message Modal';
import ModalMess from 'Components/Message Modal';
import { logout } from 'assistance/identity_service';
import { getProfile } from 'assistance/promoterClient_service';
import Loader from 'Components/loader/loader';

// interface selectState {
//   dashboard: {
//     dashboardChartData: DashboardEmailItem[];
//     loading: boolean;
//   };
// };

const Dashboard = () => {

  const [activeModal, setActiveModal] = useState(false);
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);


  useEffect(() => {


    var isFetching = true;
    if (isFetching) {
      if (isAuthenticated()) {
        // router.push("/account/login");
      } else {


      }
    }
    return () => {
      isFetching = false;
    };
  }, []);



  document.title = " Skartio - Insider";

  const [subScribeModal, setSubScribeModal] = useState<boolean>(false);

  const reports: Report[] = [
    { title: "Total Earnings", iconClass: "bx-copy-alt", description: "0" },
    { title: "Incentives", iconClass: "bx-archive-in", description: "0" },
    { title: "Credits", iconClass: "bx-purchase-tag-alt", description: "0" },
  ];

  useEffect(() => {
    setTimeout(() => {
      setSubScribeModal(true);
    }, 2000);
  }, []);

  const [periodData, setPeriodData] = useState<any>([]);
  const [periodType, setPeriodType] = useState<string>("Year");

  // const selectProperties = createSelector(
  //   (state: selectState) => state.dashboard,
  //   (dashboard) => ({
  //     chartsData: dashboard.dashboardChartData
  //   })
  // );

  // const { chartsData } = useSelector(selectProperties);

  // useEffect(() => {
  //   setPeriodData(chartsData);
  // }, [chartsData]);

  const onChangeChartPeriod = (pType: any) => {
    setPeriodType(pType);
    dispatch(onGetChartData(pType));
  };

  const dispatch = useDispatch<any>();
  useEffect(() => {
    dispatch(onGetChartData("Year"));
  }, [dispatch]);

  const handleButton1Click = () => {
    const email = sessionStorage.getItem('emailID')

    window.location.href = `/createAccount/?email=${email}`;
    setActiveModal(false);
  };

  const handleButton2Click = () => {
    setActiveModal(false);

   // logout()
   logout().then((response) => {
    if (response.data.status === 200) {
      removeToken();
      // setAuthenticated(false);
      // toast.success("Logout Success");
      sessionStorage.removeItem("token");

      let cookieName = "token";
      document.cookie =
        cookieName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

      let cookieNameClient = "clientToken";
      document.cookie =
        cookieNameClient +
        "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

      setTimeout(() => {
        window.location.href = "https://insider.skartio.com/";
      }, 1000);
    }
  });

  };

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await getProfile();

        if (response.data && response.status === 200) {
          // console.log(response.data?.data?.firstName);
          setLoader(true)

          // setProfileDat(response.data?.data);
        } else if (response.data && response.status === 203) {
          setShow(true)

        }


      } catch (error) {
        console.error('Failed to fetch profile data', error);
      }
    };

    fetchProfile();
  }, []);


  return (
    <>

      {show && (
        <ModalMess   
        button1Text='Continue'
        button2Text='Cancel & Logout'
        isOpen={show}
        toggle={() => setShow(false)} // Update toggle function
  //       onButton1Click={handleButton1Click}
  // onButton2Click={handleButton2Click}
          
        />
      )}  
        {loader === false ?  (
        <Loader/>
       ):(
        <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumb title="Dashboards" breadcrumbItem="Dashboard" />

            <Row>
              <Col xl={4}>
                <WelComeback setActiveModal={setActiveModal} />
                <MonthlyEarning />
              </Col>
              <Col xl={8}>
                <Row>
                  {(reports || []).map((report: Report, key: number) => (
                    <Col md={4} key={"_col_" + key}>
                      <Card className="mini-stats-wid">
                        <CardBody>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p className="text-muted fw-medium"> {report.title} </p>
                              <h4 className="mb-0">{report.description}</h4>
                            </div>
                            <div className="avatar-sm rounded-circle  align-self-center mini-stat-icon" style={{ backgroundColor: "#642e5c" }}>
                              <span className="avatar-title rounded-circle " style={{ backgroundColor: "#642e5c" }}>
                                <i className={"bx " + report.iconClass + " font-size-24"} ></i>
                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  ))}
                </Row>
                <Row>
                  <Col xl={6}>
                    <TopCities />
                  </Col>
                  <Col xl={6}>
                    <SocialSource />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>

              <Col xl={4}>
                <Activity />
              </Col>


              <Col xl={8}>

                <Card>
                  <CardBody>
                    <div className="d-sm-flex flex-wrap">
                      {/* <CardTitle tag="h4" className="mb-4">Email Sent</CardTitle> */}
                      <div className="ms-auto">
                        <Nav pills>
                          <NavItem>
                            <NavLink href="#" className={classNames({ active: periodType === "Week" }, "nav-link")}
                              style={{
                                backgroundColor: periodType === "Week" ? "#642e5c" : "transparent"
                              }}
                              onClick={() => {
                                onChangeChartPeriod("Week");
                              }}
                              id="one_month">Week</NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink href="#" className={classNames({ active: periodType === "Month" }, "nav-link")}
                              style={{
                                backgroundColor: periodType === "Month" ? "#642e5c" : "transparent"
                              }}
                              onClick={() => {
                                onChangeChartPeriod("Month");
                              }}
                              id="one_month" > Month </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink href="#" className={classNames({ active: periodType === "Year" }, "nav-link")}
                              style={{
                                backgroundColor: periodType === "Year" ? "#642e5c" : "transparent"
                              }}
                              onClick={() => {
                                onChangeChartPeriod("Year");
                              }}
                              id="one_month" > Year  </NavLink>
                          </NavItem>
                        </Nav>
                      </div>
                    </div>
                    <StackedColumnChart periodType={periodType} />


                  </CardBody>
                </Card>

              </Col>
            </Row>



            {/* <Row>
            <Col lg={12}>
              <LatestTransaction/>
            </Col>
          </Row> */}
          </Container>
        </div >


      </React.Fragment >
       )}
    </>

  );
}

export default Dashboard;
import React, { useEffect, useState } from "react";
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  CardTitle,
  CardSubtitle,
  NavItem,
  Nav,
  NavLink,
  Input,
} from "reactstrap";
import { getPincodeDetail } from "assistance/promoter_service";
import { fetchCountryList } from "pages/Authentication/Registerfunctions";
import {
  addLeadGen,
  getAction,
  getAllLeadGen,
  getProfile,
  updateLeadStatus,
} from "assistance/promoterClient_service";
// import { NavLink } from "react-router-dom";
import classnames from "classnames";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "./dashboard.module.scss";
interface Country {
  id: number;
  sortname: string;
  name: string;
  phoneCode: number;
}

interface ProfileData {
  phone?: string; // Assuming phone is a string and optional
  // Add other fields that exist in the profile data here
  onboardingSteps?: {
    kycFlag?: boolean;
  };
  email?: string;
}

const 
BasicTable = () => {
  const [subScribeModal, setSubScribeModal] = useState<boolean>(false);
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [qualified, setQualified] = useState<boolean>(false);
  const [converted, setconverted] = useState<boolean>(false);
  const [nonconverted, setnonconverted] = useState<boolean>(false);
  const [searchValues, setSearchValues] = useState("");
  const [actionSearch, setActionSearch] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const toggleDropdown = (index) => {
    setOpenDropdownIndex(openDropdownIndex === index ? null : index);
  };
  const [collapse, setCollapse] = useState<boolean>(false);
  // const leadDataArray = Array.isArray(leadData) ? leadData : [];
  const [leadid, setLeadId] = useState("");
  const [inputValuePincode, setInputValuePincode] = useState("");
  const [pinCodeError, setPinCodeError] = useState<string | null>(null);
  const [loader, setLoader] = useState(false);
  const [activeTab, setActiveTab] = useState<string>("");
  const [activeTabDes, setActiveTabDes] = useState<string>("All Leads");
  const [profileData, setProfileData] = useState<ProfileData | null>(null);
  const [kycFlag, setKycFlag] = useState<boolean>(false);
  const [validation, setValidation] = useState<any>({});
  const [validPincode, setValidPincode] = useState(false);
  const [officeName, setOfficeName] = useState("");
  const [leadData, setLeadData] = useState("");
  const [selectedCode, setSelectedCode] = useState("91");
  const [phoneNum, setPhoneNum] = useState("");
  const [actionId, setActionId] = useState("");
  const [actionDesc, setActionDesc] = useState("");
  const [countryCodeVal, setCountryCodeVal] = useState("");
  const [countryList, setCountryList] = useState<Country[]>([]);
  const [allLead, setAllLead] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [fontSize, setFontSize] = useState(getFontSize());
  const [formData, setFormData] = useState({
    businessName: "",
    contactPerson: "",
    phone: "",
    email: "",
    pincode: "",
    country: "India",
    countrycode: "IN",
    note: "",
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowToast(false);
    }, 1000); // 1000 milliseconds = 1 second

    return () => clearTimeout(timer); // Clear timeout if component unmounts
  }, [showToast]);

  const handleCodeChange = (e) => {
    setSelectedCode(e.target.value);
  };

  useEffect(() => {
    // Properly update state using setFormData
    setFormData({
      businessName: "",
      contactPerson: "",
      phone: "",
      email: "",
      pincode: "",
      country: "India",
      countrycode: "IN",
      note: "",
    });
  }, []);

  const updates = () => {
    getProfile().then((response) => {
      if (response.status === 200) {
        // Directly set the data as an object
        setProfileData(response?.data?.data);
        setKycFlag(response?.data?.data?.onboardingSteps?.kycFlag);
      }
    });
  };
  const isEmptyLead = Array.isArray(allLead) && allLead.length === 0;

  useEffect(() => {
    updates();
  }, [subScribeModal]);

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;

    if (name === "phone") {
      setPhoneNum(value);
    }
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleInputChanges = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    if (name === "phone") {
      setPhoneNum(value);
    }
    const combinedPhone = `${selectedCode}${"-"}${value}`;

    setFormData((prev) => ({
      ...prev,
      phone: combinedPhone,
    }));
  };

  const handleCountryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCountry = e.target.value;
    // console.log(e.target.value);
    const [countryName, countryCode] = selectedCountry.split(",");
    const country = countryList.find((c) => c.name === selectedCountry);
    setCountryCodeVal(country?.phoneCode.toString() || "");
    // Update form data with selected country

    setFormData((prevData) => ({
      ...prevData,
      country: countryName,
      countrycode: countryCode,
    }));
    // setFormData((prevData) => ({
    //   ...prevData,
    //   country: `${countryName},${countryCode}`,
    //   countrycode: countryCode,
    // }));
  };
  // Handle form submission

  function validatePhone(phone) {
    const phoneRegex = /^91-\d{10}$/; // Matches '91-' followed by 10 digits
    return phoneRegex.test(phone);
  }
  function validate(data) {
    const phoneRegex = /^[a-zA-Z\s'-]+$/; // Matches '91-' followed by 10 digits
    return phoneRegex.test(data);
  }


  

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const errors: { [key: string]: string } = {};
    if (!formData.businessName){
      errors["businessName"] = "Business Name is required.";
    }else if (!validate(formData.businessName)){
      errors["businessName"] = "Enter character Only.";
    }
    if (!formData.contactPerson){
      errors["contactPerson"] = "Contact Person is required.";
    }else if (!validate(formData.contactPerson)){
      errors["contactPerson"] = "Enter character Only.";
    }
 
    if (!formData.phone) {
      errors["phone"] = "Phone is required.";
    } else if (!validatePhone(formData.phone)) {
      errors["phone"] = "Enter valid phone number";
    } else if (formData.phone === profileData?.phone) {
      errors["phone"] = "You can't enter your own number ";
    }

    const emailRegex = /\S+@\S+\.\S+/; // Basic email validation regex
    if (!formData.email) {
      errors["email"] = "Email is required.";
    } else if (formData.email === profileData?.email) {
      errors["email"] = "You can't enter your own email.";
    }

    // if (!formData.pincode) errors["pincode"] = "Pincode is required.";
    if (!formData.country) errors["country"] = "Country is required.";

    setValidation(errors);

    if (Object.keys(errors).length === 0) {
      // console.log("Form submitted:", formData);
      //  if(kycFlag === true){
      try {
        const response = await addLeadGen(
          formData?.phone,
          formData?.businessName,
          formData?.pincode,
          formData?.contactPerson,
          formData?.email,
          formData?.countrycode,
          formData?.country
        );

        if (response.data && response.status === 200) {
          // console.log(response.data.data); // Log the fetched state name
          setShowToast(true);
          setFormData({
            businessName: "",
            contactPerson: "",
            phone: "",
            email: "",
            pincode: "",
            country: "",
            countrycode: "",
            note: "", // Add the missing `note` field
          });
          setSubScribeModal(!subScribeModal);
          toast.success("Added Lead Successfully", { autoClose: 2000 });

          // getAllLead();
        } else if (response.status === 203) {
          console.warn(
            "Received status 203: Possible warning or different condition."
          );
          setFormData({
            businessName: "",
            contactPerson: "",
            phone: "",
            email: "",
            pincode: "",
            country: "",
            countrycode: "",
            note: "", // Add the missing `note` field
          });
          if (response.data.message === "DATA IS ALREADY EXISTS") {
            toast.error("You have already claimed this lead.");
          } else if (
            response.data.message ===
            "LEAD ALREADY CLAIMED BY SOMEONE.YOU HAVE BEEN ADDED TO THE WAITLIST"
          ) {
            // toast.error("You are on the waitlist because this lead is already claimed by someone else.")
            toast.error("This lead has already been claimed by someone else.");
          } else if (response.data.message === "REFERRAL LIMIT IS EXCEEDED") {
            toast.error("Lead claim limit has been exceeded.");
          } else if (
            response.data.message === "LEAD ALREADY CLAIMED BY SOMEONE"
          ) {
            toast.error("This lead has already been claimed by someone else.");
          } else if (
            response.data.message === "YOU CAN'T CLAIM YOUR OWN NUMBER"
          ) {
            toast.error("You can't claim your own number.");
          } else if (
            response.data.message === "YOUR MONTHLY LIMIT IS EXCEEDED" ||
            "YOUR WEEKLY LIMIT IS EXCEEDED" ||
            "OUR DAILY LIMIT IS EXCEEDED"
          ) {
            toast.error("Your lead claim limit has been exceeded.");
          }

          setSubScribeModal(!subScribeModal);
        } else {
          console.error("Unexpected response status:", response.status);
        }
      } catch (err) {
        console.error("Error fetching countries:", err);
      }
      // }else{
      //   console.log("Error kycghgd ");
      //   // setFormData({
      //   //   businessName: "",
      //   //   contactPerson: "",
      //   //   phone: "",
      //   //   email: "",
      //   //   pincode: "",
      //   //   country: "",
      //   //   countrycode: "",
      //   //   note: "", // Add the missing `note` field
      //   // });
      //   // setSubScribeModal(!subScribeModal);
      // }
    }
  };

  useEffect(() => {
    const getCountries = async () => {
      try {
        const countries = await fetchCountryList(); // Call the function from JS file
        setCountryList(countries);
      } catch (err) {
        console.error("Error fetching countries:", err);
      } finally {
        // setLoading(false);
      }
    };

    getCountries();
  }, []);

  useEffect(() => {
    if (activeTabDes === "All Leads") {
      if (searchValues === " ") {
        let searchValue = "";
        let catId = "08";
        let leadStatus = "";
        getAllLead(catId, leadStatus, searchValue);
      } else {
        let catId = "08";
        let leadStatus = "";
        getAllLead(catId, leadStatus, searchValues);
      }
    } else {
      if (searchValues === " ") {
        let searchValue = "";
        let catId = "08";
        let leadStatus = "";
        getAllLead(catId, leadStatus, searchValue);
      } else {
        let catId = "08";
        let leadStatus = activeTab;

        getAllLead(catId, activeTab as string, searchValues);
      }
    }
  }, [
    subScribeModal,
    activeTab,
    // isDelete,
    qualified,
    // converted,
    // nonconverted,
    actionSearch,
  ]);

  const getAllLead = async (catId: string, leadStatus: string, searchValue: string) => {
    try {
      const lead = await getAllLeadGen(catId, leadStatus, searchValue);
      if (lead?.status === 203) {
        // toast.warning("No Record Found");
      }
      setAllLead(lead?.data?.data?.data || []);
    } catch (err) {
      console.error("Error fetching leads:", err);
      toast.error("Failed to fetch leads. Please try again.");
    }
  };
  
  async function checkPincodeAvailability(pincode: string) {
    if (pincode !== "") {
      setLoader(true);

      try {
        const response = await getPincodeDetail(pincode);

        if (response.status === 200) {
          setValidPincode(true);
          setPinCodeError(null);

          let location = "";
          let locationData = response?.data?.data;
          let countryCodeData = "";

          locationData?.forEach(
            (item: {
              officeType: string;
              officename: string;
              countryCodeData: string;
            }) => {
              if (item?.officeType === "S.O") {
                location = item?.officename;
                countryCodeData = item?.countryCodeData;
                setOfficeName(location);
                setCountryCodeVal(countryCodeData);
              }
            }
          );

          setLoader(false);
        } else {
          setPinCodeError("Failed to fetch pincode details");
          setLoader(false);
          setValidPincode(false);
        }
      } catch (error) {
        setPinCodeError("Error fetching pincode details");
        setLoader(false);
        setValidPincode(false);
      }
    } else {
      setPinCodeError("Enter your pincode");
    }
  }

  const toggleTab = (status: string, groupLabel: string) => {
    if (groupLabel) {
      setActiveTab(status); // Store all statuses as an array
      setActiveTabDes(groupLabel);
    }
  };

  useEffect(() => {
    const getAllAction = async () => {
      try {
        const action = await getAction(); // Call the function from JS file
        setLeadData(action?.data?.data?.leadSettings?.leadStatus);
        sessionStorage.setItem("settlement",JSON.stringify(action?.data?.data?.settlement));

      } catch (err) {
        console.error("Error fetching countries:", err);
      } finally {
        // setLoading(false);
      }
    };
    getAllAction();
  }, []);

  const handleQualified = async () => {
    let desc = activeTabDes;
    let statusId = "";
    try {
      // Make sure to await the function correctly
      const response = await updateLeadStatus(leadid, actionDesc, actionId);

      if (response.data && response.status === 200) {
        // console.log(`Lead ID ${leadid} status updated successfully`);
        // Add any additional success logic here
        toast.success(`Lead status updated successfully`);

        setQualified(!qualified);
      }
    } catch (error) {
      console.error(
        `Failed to update lead status for Lead ID ${leadid}:`,
        error
      );
    }
  };

  const handleQ = (
    data: React.SetStateAction<any>,
    items: React.SetStateAction<any>
  ) => {
    setLeadId(items?.leadId);
    // console.log(items?.leadId);
    // console.log("data", data);
    setActionId(data?.value);
    setActionDesc(data?.displayName);
    setQualified(true);
  };

  const handleSearchValue = (value) => {
    // console.log(value);
    setSearchValues(value);
  };

  const handlesearchs = () => {
    setActionSearch(!actionSearch);
  };

  function getFontSize() {
    if (window.innerWidth >= 1200) return "14px";
    else if (window.innerWidth >= 1030) return "10px";
    return "10px";
  }

  useEffect(() => {
    // Event handler to update font size on resize
    const handleResize = () => setFontSize(getFontSize());

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const groupedLeads = [
    {
      groupLabel: "All Leads",
      status: "all",
    },
    {
      groupLabel: "Captured Leads",
      status: "L20",
    },
    {
      groupLabel: "MQL and PQL Leads",
      status: `L23|L25`,
    },
    {
      groupLabel: "SQL Leads",
      status: `L27|L29|L31`,
    },
    {
      groupLabel: "Disqualified Leads",
      status: `L22|L24|L26|L28|L30|L32`,
    },
  ];

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="container-fluid">
        <form className="mb-4">
          <div className="g-3 row">
            <div className="col-lg-3 col-xxl-3  col-md-3">
              <div className="position-relative mt-1">
                <input
                  id="searchJob"
                  placeholder="Search your Lead"
                  type="text"
                  className="form-control "
                  onChange={(e) => handleSearchValue(e.target.value)}
                />{" "}
              </div>
            </div>

            <div className="col-lg-3 col-xxl-2 col-md-3">
              <div className="position-relative h-100 hstack gap-3">
                <button
                  type="button"
                  className="btn text-white  w-100 "
                  style={{ backgroundColor: "#642e5c" }}
                  onClick={() => handlesearchs()}
                >
                  <i className="bx bx-search-alt align-middle"></i> Find Leads
                </button>
              </div>
            </div>

            {activeTabDes === "All Leads" ? (
              <>
                <div className="col-lg-3 col-xxl-2 col-md-3">
                  <div className="position-relative h-100 hstack gap-3">
                    <button
                      className="btn btn-secondary  w-100 "
                      onClick={() => setCollapse(true)}
                    >
                      <i className="bx bx-filter-alt align-middle"></i>
                      Filter
                    </button>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            <div className="col-lg-3 col-xxl-3 col-md-3 ">
              <Row className="">
                <Col className="text-start d-flex justify-content-end">
                  <Button
                    className="font-size-15 w-md   w-100 "
                    style={{
                      backgroundColor: "white",
                      // borderColor: "#642e5c",
                      border: "2px solid #642e5c",
                      color: "#642e5c",
                    }}
                    onClick={() => setSubScribeModal(true)}
                  >
                    + Add Lead
                  </Button>
                </Col>
              </Row>
            </div>

            {activeTabDes === "All Leads" ? (
              <>
                {/* {collapse == true ? (
                  <div id="collapseExample" className="collapse show">
                    <div>
                      <div className="g-3 row">
                        <div className="col-lg-6 col-xxl-4">
                          <div>
                            <label className="form-label fw-semibold form-label">
                              Experience
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              id="inlineCheckbox1"
                              type="checkbox"
                              className="form-check-input form-check-input"
                              value="option1"
                            />
                            <label className="form-check-label form-label">
                              All
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              id="inlineCheckbox2"
                              type="checkbox"
                              className="form-check-input form-check-input"
                              value="option1"
                            />
                            <label className="form-check-label form-label">
                              Fresher
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              id="inlineCheckbox3"
                              type="checkbox"
                              className="form-check-input form-check-input"
                              value="option2"
                            />
                            <label className="form-check-label form-label">
                              1-2
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              id="inlineCheckbox4"
                              type="checkbox"
                              className="form-check-input form-check-input"
                              value="option2"
                            />
                            <label className="form-check-label form-label">
                              2-3
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              id="inlineCheckbox5"
                              type="checkbox"
                              className="form-check-input form-check-input"
                              value="option3"
                            />
                            <label className="form-check-label form-label">
                              4+
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-6 col-xxl-4">
                          <div>
                            <label className="form-label fw-semibold form-label">
                              Job Type
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              id="inlineCheckbox6"
                              type="checkbox"
                              className="form-check-input form-check-input"
                              value="option3"
                            />
                            <label className="form-check-label form-label">
                              Full Time
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              id="inlineCheckbox7"
                              type="checkbox"
                              className="form-check-input form-check-input"
                              value="option3"
                            />
                            <label className="form-check-label form-label">
                              Part Time
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              id="inlineCheckbox8"
                              type="checkbox"
                              className="form-check-input form-check-input"
                              value="option3"
                            />
                            <label className="form-check-label form-label">
                              Freelance
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              id="inlineCheckbox9"
                              type="checkbox"
                              className="form-check-input form-check-input"
                              value="option3"
                            />
                            <label className="form-check-label form-label">
                              Internship
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-4 col-xxl-4"></div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )} */}
              </>
            ) : (
              ""
            )}
          </div>
        </form>

        {/* <Nav pills justified>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "all" })}
              style={{
                backgroundColor: activeTab === "all" ? "#642e5c" : "white",
                fontSize: fontSize,
                height:'50px'
              }}
              onClick={() => {
                toggleTab("all", "all lead");
              }}
            >
              <i className="bx bx-chat font-size-30 d-sm-none" />
              <span className="d-none d-sm-block">All Lead</span>
            </NavLink>
          </NavItem>

          {Array.isArray(leadData) &&
            leadData.map((item, index) => {

              return(
              <NavItem key={index}>
                <NavLink
                  className={classnames({ active: activeTab === item?.status })}
                  style={{
                    backgroundColor:
                      activeTab === item?.status ? "#642e5c" : "white",
                    fontSize: fontSize,
                    height:'50px'
                  }}
                  onClick={() =>  
                    toggleTab(item?.status, item?.statusCustomDesc)
                  }
                >
                  <i className="bx bx-chat font-size-30 d-sm-none" />
                  <span className="d-none d-sm-block">
                    {item?.statusCustomDesc}
                  </span>
                </NavLink>
              </NavItem>
          ) })}
        </Nav> */}
        <div
        //   className={`${styles["nav-scroll-container"]}`}
        //  tyle={{ overflowX: " sauto", whiteSpace: "nowrap" }}
        >
          <Nav pills justified>
            {Array.isArray(groupedLeads) &&
              groupedLeads.map((item, index) => (
                <NavItem key={index}>
                  <NavLink
                    className={classnames({
                      active: activeTabDes === item?.groupLabel, // Compare with groupLabel
                    })}
                    style={{
                      backgroundColor:
                        activeTabDes === item?.groupLabel ? "#642e5c" : "white",
                      fontSize: fontSize,
                      // height: "50px",
                      whiteSpace: "nowrap",
                    }}
                    onClick={() => toggleTab(item?.status, item?.groupLabel)}
                  >
                    <i className="bx bx-chat font-size-30 d-sm-none" />
                    <span className="d-none d-sm-block">
                      {item?.groupLabel}
                    </span>
                  </NavLink>
                </NavItem>
              ))}
          </Nav>
        </div>

        {allLead && (
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <CardTitle className="h4"> </CardTitle>
                  <CardSubtitle className="card-title-desc"> </CardSubtitle>

                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead className="table-light">
                        <tr>
                          <th>#</th>
                          <th>Business Name</th>
                          <th>Contact Name</th>
                          <th>Email</th>
                          <th>Phone</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(allLead) &&
                          allLead.map((item, index) => (
                            <tr key={index}>
                              <th scope="row">{index + 1}</th>
                              <td>{item.leadName}</td>
                              <td>{item.additionalInfo?.pocName}</td>
                              <td>{item.additionalInfo?.pocEmail}</td>
                              <td>{item.leadUniqueID}</td>
                              <td>
                                <span className="bg-info badge  p-2">
                                  {item.leadStatusDesc}
                                </span>
                              </td>
                              {/* 
                              {activeTab === "all" ? ( */}
                              
                              <td style={{ width: "10%" }}>
                                <>
                                  <div className="dropdown">
                                  {item?.action?.forward?.length > 0  ?(
                                    <>
                                    <button
                                      className="btn btn-sm dropdown-toggle"
                                      type="button"
                                      onClick={() => toggleDropdown(index)}
                                      aria-expanded={
                                        openDropdownIndex === index
                                      }
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 128 512"
                                        width="20"
                                        height="20"
                                      >
                                        <path d="M64 360a56 56 0 1 0 0 112 56 56 0 1 0 0-112zm0-160a56 56 0 1 0 0 112 56 56 0 1 0 0-112zM120 96A56 56 0 1 0 8 96a56 56 0 1 0 112 0z"></path>
                                      </svg>
                                    </button>
                                     <ul
                                     className={`dropdown-menu${
                                       openDropdownIndex === index
                                         ? " show"
                                         : ""
                                     }`}
                                     aria-labelledby="dropdownMenuButton"
                                     style={{
                                       position: "relative",
                                       width: "100%",
                                       maxHeight: "70px", // Adjust this value based on your needs
                                       overflowY: "scroll", // Keeps scrolling functionality, but hides arrows
                                       scrollbarWidth: "none", // Hides scrollbar for Firefox
                                       msOverflowStyle: "none", // Hides scrollbar for IE and Edge
                                     }}
                                   >
                                     {item?.action && (
                                       <>
                                         {item?.action?.forward &&
                                           item?.action?.forward.map(
                                             (i, index) => (
                                               <li key={`forward-${index}`}>
                                                 <button
                                                   className="dropdown-item btn btn-sm btn-soft-primary"
                                                   onClick={() => {
                                                     handleQ(i, item);
                                                     setIsOpen(false);
                                                   }}
                                                 >
                                                   {i?.displayName}
                                                 </button>
                                               </li>
                                             )
                                           )}

                                         {item?.action?.backward &&
                                           item?.action?.backward.map(
                                             (i, index) => (
                                               <li key={`backward-${index}`}>
                                                 <button
                                                   className="dropdown-item btn btn-sm btn-soft-secondary"
                                                   onClick={() => {
                                                     handleQ(i, item);
                                                     setIsOpen(false);
                                                   }}
                                                 >
                                                   {i?.displayName}
                                                 </button>
                                               </li>
                                             )
                                           )}
                                       </>
                                     )}
                                   </ul>
                                   </>
                                  ):'No Action'}
                                   
                                  </div>
                                </>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  
                   
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}

{isEmptyLead && (
  <div className="d-flex justify-content-center font-size-20  mt-5 text-blod">
    Currently No record
  </div>
)}

        {/* <Breadcrumbs title="Tables" breadcrumbItem="Basic Tables" /> */}
      </div>
      <Modal
        isOpen={subScribeModal}
        centered
        toggle={() => setSubScribeModal(!subScribeModal)}
      >
        <ModalHeader
          className="border-bottom-0"
          toggle={() => setSubScribeModal(!subScribeModal)}
        />
        <ModalBody>
          <form className="needs-validation" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label">Business Name</label>
                  <input
                    name="businessName"
                    placeholder="Lead name"
                    autoComplete="off"
                    type="text"
                    className={`form-control ${
                      validation.businessName ? "is-invalid" : ""
                    }`}
                    value={formData.businessName}
                    onChange={handleInputChange}
                  />
                  {validation.businessName && (
                    <div className="invalid-feedback">
                      {validation.businessName}
                    </div>
                  )}
                </div>
              </div>

              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label">Contact Person</label>
                  <input
                    name="contactPerson"
                    autoComplete="off"
                    className={`form-control ${
                      validation.contactPerson ? "is-invalid" : ""
                    }`}
                    placeholder="Contact Person"
                    value={formData.contactPerson}
                    onChange={handleInputChange}
                  />
                  {validation.contactPerson && (
                    <div className="invalid-feedback">
                      {validation.contactPerson}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label">Phone</label>

                  <div className="input-group">
                    <select
                      className="form-select"
                      value={selectedCode}
                      onChange={handleCodeChange} // Ensure this is being handled properly
                      style={{ maxWidth: "60px" }}
                    >
                      {countryList &&
                        countryList.map((country, index) => (
                          <option
                            key={index}
                            value={country.phoneCode.toString()}
                          >
                            {country.phoneCode}
                          </option>
                        ))}
                    </select>
                    <Input
                      className={`form-control ${
                        validation.phone ? "is-invalid" : ""
                      }`}
                      type="tel"
                      name="phone"
                      autoComplete="off"
                      // value={phoneNum || ""}
                      onChange={handleInputChanges}
                      onBlur={validation.handleBlur}
                      placeholder=" phone "
                      // invalid={
                      //   validation.touched.phone &&
                      //   validation.errors.phone
                      //     ? true
                      //     : false
                      // }
                    />
                    {validation.phone && (
                      <div className="invalid-feedback">{validation.phone}</div>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label">Email</label>
                  <input
                    name="email"
                    autoComplete="off"
                    className={`form-control ${
                      validation.email ? "is-invalid" : ""
                    }`}
                    placeholder="Enter Email"
                    type="email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                  {validation.email && (
                    <div className="invalid-feedback">{validation.email}</div>
                  )}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label">Current Country</label>
                  <select
                    name="country"
                    className={`form-select ${
                      validation.country ? "is-invalid" : ""
                    }`}
                    // value={formData.country}
                    value={`${formData.country},${formData.countrycode}`}
                    onChange={handleCountryChange}
                  >
                    <option value="">Select Country</option>
                    {countryList &&
                      countryList.map((country, index) => (
                        <option
                          key={index}
                          value={`${country.name},${country.sortname}`}
                        >
                          {country.name}
                        </option>
                      ))}
                  </select>
                  {validation.country && (
                    <div className="invalid-feedback">{validation.country}</div>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label">Pin code</label>
                  <input
                    name="pincode"
                    className={`form-control`}
                    placeholder="Pincode"
                    value={formData.pincode}
                    onChange={(e) => {
                      handleInputChange(e);
                      setInputValuePincode(e.target.value);
                    }}
                    // onBlur={(e) => checkPincodeAvailability(e.target.value)}
                    autoComplete="off"
                  />
                  {/* {pinCodeError && (
                    <span className="text-danger">{pinCodeError}</span>
                  )}
                  {validation.pincode && (
                    <div className="invalid-feedback">{validation.pincode}</div>
                  )} */}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="mb-3">
                  <label className="form-label">Notes</label>
                  <textarea
                    name="note"
                    className="form-control"
                    placeholder="Enter Notes"
                    value={formData.note}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>

            <div className="text-center">
              <Button type="submit" style={{ backgroundColor: "#642e5c" }}>
                Add Lead
              </Button>
            </div>
          </form>
        </ModalBody>
      </Modal>
      {/* <ToastContainer /> */}

      {/* gjhgjgfjgfjgjghfh */}
      <Modal
        isOpen={qualified}
        centered
        toggle={() => setQualified(!qualified)}
      >
        <ModalHeader
          className="border-bottom-0"
          toggle={() => setQualified(!qualified)}
        />
        <ModalBody>
          <form className="needs-validation">
            <div className="px-4 text-center modal-body">
              <p className="text-muted font-size-16 mb-4">
                Are you sure you want to change lead status
              </p>
              <div className="hstack gap-2 justify-content-center mb-0">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => handleQualified()}
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setQualified(!qualified)}
                >
                  No
                </button>
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default BasicTable;

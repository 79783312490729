import React, { useEffect, useState } from "react";
import { Modal, ModalBody, Button } from "reactstrap";
import styles from "./wallet-modal.module.scss";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getPromoterPaymentmethod, settlementRequest } from "assistance/promoterClient_service";
import CompanyProfileImage from "Components/company-profile-image/company-profile-image";

interface OTPModalProps {
  isOpen: boolean;
  toggle: () => void;
  firstName: string ;
  Phone: string;
  email: string;
  comissionRate:string;
  totalAmt:string;
  }
interface MethodData {
  IFSCCode: string;
  IsPrimary: boolean;
  RefId: string;
  RefNumber: string;
  SWIFTCode: string;
  Type: string;
  method: string;
  methodName: string;
  seq: number;
  status: string;
}

type SelectedType = {
  methodName: string;
  RefId: string; // Add any other properties that `selected` has.
  method:string;
  RefNumber:string;
  IFSCCode :string;
  Type:string;
  IsPrimary:boolean;
  seq:number;
  status:string;
};

const WalletWithdraw: React.FC<OTPModalProps> = ({ isOpen, toggle, firstName, Phone, email,comissionRate,totalAmt }) => {
  const [methodsData, setMethodsData] = useState<MethodData[]>([]);
  const [selectedMethodId, setSelectedMethodId] = useState<string | null>(null); // Track by unique ID
  const [Pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [upiMethods, setUpiMethods] = useState([]);
  const [baMethods, setBaMethods] = useState([]);
  const [amount, setAmount] = useState(''); // State to store input value

  const handleChange = (e) => {
    setAmount(e.target.value); // Update state with input value

  };

console.log(amount)
  const [selected, setSelected] = useState<SelectedType []>([]);
const[payment,setPayment]=useState(false)

  const [upiCount, setUpiCount]=useState(); // Example state for UPI count
    const [baCount, setBaCount]=useState();   // Example state for BA count
  useEffect(() => {
    getPaymentMethods();
  }, [currentPage]);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const getPaymentMethods = async () => {
    try {
      const response = await getPromoterPaymentmethod(currentPage, 2); // Fetch payment methods
      const paymentMethods = response?.data?.data?.data || [];
      const storedMethodsData = JSON.parse(sessionStorage.getItem('methodsData') || '[]');      // Set the fetched methods data
      setMethodsData(paymentMethods);
  
      // Calculate the total pages based on the total count
      const pages = Math.ceil(response.data?.data?.totalCount / 2); // Use `Math.ceil` for correct rounding
      setTotalPages(pages);
      setPages(response.data?.data?.totalCount);
  
      const upiMethods = storedMethodsData.filter((method) => method.method === "UPI");
      const baMethods = storedMethodsData.filter((method) => method.method === "BA");
  
      // Log the filtered arrays (optional)
      console.log("UPI Methods:", upiMethods);
      console.log("BA Methods:", baMethods);
  
      // Set the counts for each type
      setUpiCount(upiMethods.length);
      setBaCount(baMethods.length);
  
      // Set separate arrays to state if needed
      setUpiMethods(upiMethods); // Example state for UPI methods array
      setBaMethods(baMethods);   // Example state for BA methods array
    } catch (err) {
      console.error("Error fetching payment methods:", err);
    }
  };
  
  const handleSelectMethod = (id: string) => {
    setSelectedMethodId(id); // Store the unique ID of the selected item
  };

  const handleConfirmSelection = () => {
    const selectedData = methodsData.find((method) => method.RefId === selectedMethodId);
    setSelected([]);

    if (selectedData) {
      // Retrieve the current list of selected payment methods from sessionStorage
      const existingData = JSON.parse(sessionStorage.getItem('selectedPaymentMethods') || '[]');
  debugger
      // Add the new selected data to the existing list
      const updatedData = [ selectedData];
  
      // Store the updated list in sessionStorage
      sessionStorage.setItem('selectedPaymentMethods', JSON.stringify(updatedData));
  
      // Notify the user of the successful selection
      // toast.success(`Selected: ${selectedData.methodName}`);
      setSelected(updatedData);
      console.log("updatedData",updatedData)
  
      // Update state or perform additional actions
      setPayment(true);
    } else {
      toast.error("Please select a payment method!");
    }
  };
  
  const handleTranction =async ()=>{
    const withdrawData = JSON.parse(sessionStorage.getItem('settlement') || '[]');      // Set the fetched methods data

console.log(withdrawData)
     console.log(withdrawData)
     console.log("selected",selected)


     if(withdrawData[0]?.withdrawalLimit?.valueType === 'P'){
      // console.log(withdrawData[0]?.withdrawalLimit?.value)
      const payload = {
        status:selected[0]?.status,
        method: selected[0]?.method,
        methodName: selected[0]?.methodName,
        Type:selected[0]?.Type,
        RefNumber: selected[0]?.RefNumber,
        RefId: selected[0]?.RefId,
        IFSCCode: selected[0]?.IFSCCode,
        IsPrimary: selected[0]?.IsPrimary,
        seq: selected[0]?.seq,
        reqValue: amount,
        totalBalance: totalAmt,
        availableBalance: comissionRate,
      };
      // console.log("payload",payload)
      const values = parseInt(amount, 10); // Ensure 'amount' is a string or number
      // console.log(values)
      let discountedValue = values * 90 / 100;
    
      let disValues=JSON.stringify(values)
      // console.log("disValues",disValues);
      let disValue=JSON.stringify(discountedValue)
      // console.log(discountedValue);
      
      if( totalAmt == '0'){
       
        toast.error('Transaction processed1.');
        
  
      }else if (totalAmt >= disValue) {
        
        const payload = {
          status: selected[0]?.status,
          method: selected[0]?.method,
          methodName: selected[0]?.methodName,
          Type: selected[0]?.Type,
          RefNumber: selected[0]?.RefNumber,
          RefId: selected[0]?.RefId,
          IFSCCode: selected[0]?.IFSCCode,
          IsPrimary: selected[0]?.IsPrimary,
          seq: selected[0]?.seq,
          reqValue: amount,
          totalBalance: totalAmt,
          availableBalance: comissionRate,
        };
        
        try {
          const response = await settlementRequest(
            payload.status,
            payload.method,
            payload.methodName,
            payload.Type,
            payload.RefNumber,
            payload.RefId,
            payload.IFSCCode,
            payload.IsPrimary,
            payload.seq,
            payload.reqValue,
            payload.totalBalance,
            payload.availableBalance
          );
        
          if (response.status === 200) {
            toast.success("Transaction settled successfully");
            console.log("Response:", response.data);
          } else {
            toast.warning(
              "Transaction processed but unexpected response received"
            );
            console.warn("Unexpected Response:", response);
          }
        } catch (error) {
          toast.error("Failed to process transaction");
          console.error("Error during settlement request:", error);
        }
        
        
      }
      else  if(totalAmt < disValue){
        toast.error('Transaction cannot be processed: the withdrawal amount exceeds the available balance.');
      }else  if(totalAmt === disValue){
        const payload = {
          status: selected[0]?.status,
          method: selected[0]?.method,
          methodName: selected[0]?.methodName,
          Type: selected[0]?.Type,
          RefNumber: selected[0]?.RefNumber,
          RefId: selected[0]?.RefId,
          IFSCCode: selected[0]?.IFSCCode,
          IsPrimary: selected[0]?.IsPrimary,
          seq: selected[0]?.seq,
          reqValue: amount,
          totalBalance: totalAmt,
          availableBalance: comissionRate,
        };
        
        try {
          const response = await settlementRequest(
            payload.status,
            payload.method,
            payload.methodName,
            payload.Type,
            payload.RefNumber,
            payload.RefId,
            payload.IFSCCode,
            payload.IsPrimary,
            payload.seq,
            payload.reqValue,
            payload.totalBalance,
            payload.availableBalance
          );
        
          if (response.status === 200) {
            toast.success("Transaction settled successfully");
            console.log("Response:", response.data);
          } else {
            toast.warning(
              "Transaction processed but unexpected response received"
            );
            console.warn("Unexpected Response:", response);
          }
        } catch (error) {
          toast.error("Failed to process transaction");
          console.error("Error during settlement request:", error);
        }
      }
      // console.log(values)


     }else if(withdrawData[0]?.withdrawalLimit?.valueType === 'V'){
 

      // console.log(withdrawData[0]?.withdrawalLimit?.value)
      const payload = {
        status:selected[0]?.status,
        method: selected[0]?.method,
        methodName: selected[0]?.methodName,
        Type:selected[0]?.Type,
        RefNumber: selected[0]?.RefNumber,
        RefId: selected[0]?.RefId,
        IFSCCode: selected[0]?.IFSCCode,
        IsPrimary: selected[0]?.IsPrimary,
        seq: selected[0]?.seq,
        reqValue: amount,
        totalBalance: totalAmt,
        availableBalance: comissionRate,
      };
      // console.log("payload",payload)
      const values = parseInt(amount, 10); // Ensure 'amount' is a string or number
      // let discountedValue = values * 90 / 100;
      debugger
     
      let disValues=JSON.stringify(values)
      // console.log(disValues);
      
      if( totalAmt == '0'){
        debugger
        toast.error('Transaction processed1.');
        
  
      }else if (totalAmt >= disValues) {
        debugger
        const payload = {
          status: selected[0]?.status,
          method: selected[0]?.method,
          methodName: selected[0]?.methodName,
          Type: selected[0]?.Type,
          RefNumber: selected[0]?.RefNumber,
          RefId: selected[0]?.RefId,
          IFSCCode: selected[0]?.IFSCCode,
          IsPrimary: selected[0]?.IsPrimary,
          seq: selected[0]?.seq,
          reqValue: amount,
          totalBalance: totalAmt,
          availableBalance: comissionRate,
        };
        
        try {
          const response = await settlementRequest(
            payload.status,
            payload.method,
            payload.methodName,
            payload.Type,
            payload.RefNumber,
            payload.RefId,
            payload.IFSCCode,
            payload.IsPrimary,
            payload.seq,
            payload.reqValue,
            payload.totalBalance,
            payload.availableBalance
          );
        
          if (response.status === 200) {
            toast.success("Transaction settled successfully");
            console.log("Response:", response.data);
          } else {
            toast.warning(
              "Transaction processed but unexpected response received"
            );
            console.warn("Unexpected Response:", response);
          }
        } catch (error) {
          toast.error("Failed to process transaction");
          console.error("Error during settlement request:", error);
        }
        
        
      }
      else  if(totalAmt < disValues){
        toast.error('Transaction cannot be processed: the withdrawal amount exceeds the available balance.');
      }else  if(totalAmt === disValues){
        const payload = {
          status: selected[0]?.status,
          method: selected[0]?.method,
          methodName: selected[0]?.methodName,
          Type: selected[0]?.Type,
          RefNumber: selected[0]?.RefNumber,
          RefId: selected[0]?.RefId,
          IFSCCode: selected[0]?.IFSCCode,
          IsPrimary: selected[0]?.IsPrimary,
          seq: selected[0]?.seq,
          reqValue: amount,
          totalBalance: totalAmt,
          availableBalance: comissionRate,
        };
        
        try {
          const response = await settlementRequest(
            payload.status,
            payload.method,
            payload.methodName,
            payload.Type,
            payload.RefNumber,
            payload.RefId,
            payload.IFSCCode,
            payload.IsPrimary,
            payload.seq,
            payload.reqValue,
            payload.totalBalance,
            payload.availableBalance
          );
        
          if (response.status === 200) {
            toast.success("Transaction settled successfully");
            console.log("Response:", response.data);
          } else {
            toast.warning(
              "Transaction processed but unexpected response received"
            );
            console.warn("Unexpected Response:", response);
          }
        } catch (error) {
          toast.error("Failed to process transaction");
          console.error("Error during settlement request:", error);
        }
      }
      // console.log(values)


     }

  }

  const formatPhoneNumber = (phoneNumber: string) => {
    const phoneStr = phoneNumber.toString();
    const hiddenPart = phoneStr.slice(0, -4).replace(/\d/g, "*");
    const visiblePart = phoneStr.slice(-4);
    return hiddenPart + visiblePart;
  };

  const formatEmailUsername = (email: string) => {
    const [username, domain] = email.split("@");
    const hiddenPart = username.slice(0, -2).replace(/./g, "*");
    const visiblePart = username.slice(-2);
    return hiddenPart + visiblePart + "@" + domain;
  };

  return (
    <>
      <ToastContainer />
      <Modal isOpen={isOpen} toggle={toggle} className={` ${styles.modals}`}>
        <div
          className={`d-flex justify-content-between w-100 mb-0 border-bottom px-5 py-4 ${styles["bg-head"]}`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
            width={20}
            height={20}
            onClick={() => toggle()}
            fill="white"
          >
            <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
          </svg>
          <div className="d-flex flex-column">
            <h5 className="text-white mt-1">{firstName}</h5>
            <p className="mb-1 font-size-12 text-white">{email}</p>
            <p className="mb-1 font-size-12 text-white">{Phone}</p>
          </div>
          <div>
            <div className={`${styles["img-wraps"]}`}>
              <CompanyProfileImage name={firstName as string} type={"user-profile"} />
              <div className={styles["profile-overlay"]}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width={25} height={25}>
                  <path d="M0 80C0 53.5 21.5 32 48 32l96 0c26.5 0 48 21.5 48 48l0 96c0 26.5-21.5 48-48 48l-96 0c-26.5 0-48-21.5-48-48L0 80zM64 96l0 64 64 0 0-64L64 96zM0 336c0-26.5 21.5-48 48-48l96 0c26.5 0 48 21.5 48 48l0 96c0 26.5-21.5 48-48 48l-96 0c-26.5 0-48-21.5-48-48l0-96zm64 16l0 64 64 0 0-64-64 0zM304 32l96 0c26.5 0 48 21.5 48 48l0 96c0 26.5-21.5 48-48 48l-96 0c-26.5 0-48-21.5-48-48l0-96c0-26.5 21.5-48 48-48zm80 64l-64 0 0 64 64 0 0-64zM256 304c0-8.8 7.2-16 16-16l64 0c8.8 0 16 7.2 16 16s7.2 16 16 16l32 0c8.8 0 16-7.2 16-16s7.2-16 16-16s16 7.2 16 16l0 96c0 8.8-7.2 16-16 16l-64 0c-8.8 0-16-7.2-16-16s-7.2-16-16-16s-16 7.2-16 16l0 64c0 8.8-7.2 16-16 16l-32 0c-8.8 0-16-7.2-16-16l0-160zM368 480a16 16 0 1 1 0-32 16 16 0 1 1 0 32zm64 0a16 16 0 1 1 0-32 16 16 0 1 1 0 32z" />
                </svg>
              </div>
            </div>
          </div>
        </div>

        <ModalBody>

        <div>


{payment === false ?(
<>
<div className="mb-3">
            <div className={`font-size-20 `}>Set up payment methods</div>
<div className="row">
  <div className={`col-lg-6  `}>
            <p className="d-flex justify-content-center"> <i
                                 className={`mdi mdi-bank ${styles["wallet-icons"]}`}
                               ></i></p>
                               <span  className="d-flex justify-content-center">Bank Account</span>
                               <span  className="d-flex justify-content-center">{baCount} account</span>

                               </div>  <div className={`col-lg-6 `}>
            <p  className="d-flex justify-content-center" > <svg
                                   xmlns="http://www.w3.org/2000/svg"
                                   viewBox="0 0 48 48"
                                 width="48px"
                                  height="48px"
                                  baseProfile="basic"
                                 >
                                   <polygon
                                     fill="#388e3c"
                                     points="29,4 18,45 40,24"
                                   />
                                   <polygon
                                     fill="#f57c00"
                                     points="21,3 10,44 32,23"
                                   />
                                 </svg></p>
                               <span  className="d-flex justify-content-center mt-4">UPI</span>
                               <span  className="d-flex justify-content-center">{upiCount} account</span>

                               </div>
          </div></div>
          <div className="row">
  <div className="col-md-12">
    <div className="mb-3">
      {/* <h5>UPI Payment Methods</h5> */}
      {methodsData.filter((item) => item.method === "UPI").map((item) => (
        <div
          key={item.RefId}
          className={`card ${
            selectedMethodId === item.RefId ? styles.selected : ""
          }`}
          onClick={() => handleSelectMethod(item.RefId)}
          style={{
            border: selectedMethodId === item.RefId ? "2px solid #388e3c" : "1px solid #ddd",
            height: "140px",
            width: "100%",
            fontSize: "15px",
            padding: "10px",
            cursor: "pointer",
          }}
        >
          <div className="row">
            <div className="col-2">
              <div className={`${styles["wallet-icon"]}`}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 48 48"
                  width="48px"
                  height="48px"
                  baseProfile="basic"
                >
                  <polygon fill="#388e3c" points="29,4 18,45 40,24" />
                  <polygon fill="#f57c00" points="21,3 10,44 32,23" />
                </svg>
              </div>
            </div>
            {item?.IsPrimary === true ? (
              <p>Account</p>
            ):''}
            <div className={`col-4 ${styles["wallet-font"]}`}>
              <p>Payment Method</p>
              <p>UPI ID</p>

            </div>
            <div className={`col-4 ${styles["wallet-font"]}`}>
            {item?.IsPrimary === true ? (
              <p> <b>Primary</b></p>
            ):''}
              <p>
                <b>{item?.methodName}</b>
              </p>
              <p>
                <b>{formatEmailUsername(item?.RefId)}</b>
              </p>
            </div>
          </div>
        </div>
      ))}

      {/* <h5>Banking Payment Methods</h5> */}
      {methodsData.filter((item) => item.method === "BA").map((item) => (
        <div
          key={item.RefId}
          className={`card ${
            selectedMethodId === item.RefId ? styles.selected : ""
          }`}
          onClick={() => handleSelectMethod(item.RefId)}
          style={{
            border: selectedMethodId === item.RefId ? "2px solid #388e3c" : "1px solid #ddd",
            height: "140px",
            width: "100%",
            fontSize: "15px",
            padding: "10px",
            cursor: "pointer",
          }}
        >
          <div className="row">
            <div className="col-2">
              <i className={`mdi mdi-bank ${styles["wallet-icon"]}`}></i>
            </div>
          
            <div className={`col-4 ${styles["wallet-font"]}`}>
            {item?.IsPrimary === true ? (
              <p>Account</p>
            ):''}
              <p>Payment Method</p>
              <p>Account Type</p>
              <p>Bank Account</p>
            </div>
            <div className={`col-4 ${styles["wallet-font"]}`}>
            {item?.IsPrimary === true ? (
              <p> <b>Primary</b></p>
            ):''}
              <p>
                <b>{item?.methodName}</b>
              </p>
              <p>
                <b>{item?.Type}</b>
              </p>
              <p>
                <b>{formatPhoneNumber(item?.RefNumber)}</b>
              </p>
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>

  {Pages > 2 && (
    <div className={`mt-2 ${styles.pagination}`}>
      {[...Array(totalPages)].map((_, index) => (
        <button
          key={`page-${index}`}
          onClick={() => handlePageChange(index + 1)}
          className={`${styles["page-item"]} ${
            currentPage === index + 1 ? styles.active : ""
          }`}
        >
          {index + 1}
        </button>
      ))}
    </div>
  )}
  <div className="col-md-12 text-end">
    <Button color="primary" onClick={handleConfirmSelection}>
      Confirm Selection
    </Button>
  </div>
</div>
</>
):(
  <>
  <div className="row">
  <div className={`col-lg-6  `}>
            <p className="d-flex justify-content-center"> <i
                                 className={`mdi mdi-bank ${styles["wallet-icons"]}`}
                               ></i></p>
                               <span  className="d-flex justify-content-center">Bank Account</span>
                               <span  className="d-flex justify-content-center">{baCount} account</span>

                               </div>  <div className={`col-lg-6 `}>
            <p  className="d-flex justify-content-center" > <svg
                                   xmlns="http://www.w3.org/2000/svg"
                                   viewBox="0 0 48 48"
                                 width="48px"
                                  height="48px"
                                  baseProfile="basic"
                                 >
                                   <polygon
                                     fill="#388e3c"
                                     points="29,4 18,45 40,24"
                                   />
                                   <polygon
                                     fill="#f57c00"
                                     points="21,3 10,44 32,23"
                                   />
                                 </svg></p>
                               <span  className="d-flex justify-content-center mt-4">UPI</span>
                               <span  className="d-flex justify-content-center">{upiCount} account</span>

                               </div>
          </div>
    {selected[0]?.method === "BA" ?(
<>
<div className="row mt-4">
          <div className={`font-size-20 mb-3 `}>Selected Method</div>
            
           
            <div className="col-2">
              <i className={`mdi mdi-bank ${styles["wallet-icon"]}`}></i>
              </div>
            <div className={`col-4 ${styles["wallet-font"]}`}>
              <p>Payment Method</p>
              <p>Account Type</p>
              <p>Account Number</p>
              <p>Available Balance</p>
            </div>
            <div className={`col-4 ${styles["wallet-font"]}`}>
              <p>
                <b>{selected[0]?.methodName}</b>
              </p>
              <p>
                <b>{selected[0]?.Type === "Current" ? "Current Account" :"Savings Account"}</b>
              </p>
              <p>
                <b>{selected[0]?.RefNumber}</b>
              </p>
              
              <p>
                <b>{totalAmt}</b>
              </p>
 

            </div>
<div   style={{paddingLeft:"100px",paddingRight:"100px"} }
>
<input
      type="text"
      placeholder="Enter Amount"
      value={amount} // Bind state to input value
      style={{
        width: '100%',
        padding: '10px',
        fontSize: '12px',
        border: '1px solid #ccc',
        borderRadius: '5px',
        outline: 'none',
      }}
      onKeyDown={(e) => {
        const allowedKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'];
        if (
          !/[0-9]/.test(e.key) && // Allow numeric keys
          !allowedKeys.includes(e.key) // Allow control keys
        ) {
          e.preventDefault();
        }
      }}
      onChange={handleChange} // Listen to changes and update state
    />
</div>
 <div className="col-md-12 text-end mt-3 mb-4">
    <Button color="primary" onClick={()=>handleTranction()}>
      Confirm Selection
    </Button>
  </div>
</div>
          
</>
    ):(
<>
<div className="row mt-4">
          <div className={`font-size-20 mb-3 `}>Selected Account</div>
            <div className="col-2">
              <div className={`${styles["wallet-icon"]}`}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 48 48"
                  width="48px"
                  height="48px"
                  baseProfile="basic"
                >
                  <polygon fill="#388e3c" points="29,4 18,45 40,24" />
                  <polygon fill="#f57c00" points="21,3 10,44 32,23" />
                </svg>
              </div>
            </div>
            <div className={`col-4 ${styles["wallet-font"]}`}>
              <p>Payment Method</p>
              <p>UPI ID</p>
              <p>Available Balance</p>

            </div>
            <div className={`col-4 ${styles["wallet-font"]}`}>
              <p>
                <b>{selected[0]?.methodName}</b>
              </p>
              <p>
                <b>{selected[0]?.RefId}</b>
              </p>
              <p>
                <b>{totalAmt}</b>
              </p>
            </div>
            <div   style={{paddingLeft:"100px",paddingRight:"100px"} }
>
<input
      type="text"
      placeholder="Enter Amount"
      value={amount} // Bind state to input value
      style={{
        width: '100%',
        padding: '10px',
        fontSize: '12px',
        border: '1px solid #ccc',
        borderRadius: '5px',
        outline: 'none',
      }}
      onKeyDown={(e) => {
        const allowedKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'];
        if (
          !/[0-9]/.test(e.key) && // Allow numeric keys
          !allowedKeys.includes(e.key) // Allow control keys
        ) {
          e.preventDefault();
        }
      }}
      onChange={handleChange} // Listen to changes and update state
    />
</div>
 <div className="col-md-12 text-end mt-3 mb-4">
    <Button color="primary" onClick={()=>handleTranction()}>
      Confirm Selection
    </Button>
  </div>
          </div>
</>
    )}
         
    
  </>
)}

  

        </div>
          
        </ModalBody>
      </Modal>
    </>
  );
};

export default WalletWithdraw;

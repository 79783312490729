import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, Row, FormGroup, Label, FormFeedback } from "reactstrap";
import styles from './email-modal.module.scss';
import { ToastContainer, toast } from "react-toastify";
import { UpdateOTP } from "assistance/promoterClient_service";

interface OTPModalProps {
  isOpen: boolean;
  toggle: () => void;
  toggles: () => void;

}

const EmailModal: React.FC<OTPModalProps> = ({ isOpen, toggle,toggles }) => {
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string>("");

  const validateEmail = (value: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent default behavior to avoid unintended submissions
      submitForm(e);
    }
  };


  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setEmail(value);

    if (value.trim() === "") {
      setError("Email is required");
    } else if (!validateEmail(value)) {
      setError("Invalid email format");
    } else {
      setError("");
    }
  };

  const submitForm = async (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (!email || error) {
      toast.error("Please provide a valid email before submitting.");
      return;
    }
    console.log(email)

sessionStorage.setItem("verifyEmail",email)
    const payload = {
       value:email,
      identityType:"EMAIL",
      type: "BUSINESSUSER",
      
    };
    try {
      const response = await UpdateOTP(
        payload?.value,
        payload?.identityType,
        payload?.type,
      );

      if (response.status === 200) {
        toast.success(" Successfully ");
        toggle()
        toggles()
        // setdata(true)
        // const callbackUrl = '/dashboard';
        
        // setTimeout(() => {
        //   window.location.href = callbackUrl;    
        // }, 1000);
      } else if(response.status === 202) {
        toast.error("You already have an account with this email")
      }
      else {
        toast.error("Some error while changing this email, try again")
      }
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  return (
    <>
      <ToastContainer />

      <Modal isOpen={isOpen} toggle={toggle} className={`mt-5 ${styles.modal}`} onKeyDown={handleKeyDown}>
        <ModalHeader toggle={toggle}>Please enter the new email</ModalHeader>
        <ModalBody>
          <Row>
            <FormGroup className="mb-3">
              <Label htmlFor="validationCustom04">New Email ID</Label>
              <Input
                name="email"
                autoComplete="off"
                placeholder="Enter Email"
                type="email"
                value={email}
                onChange={handleEmailChange}
                invalid={!!error}
              />
              {error && <FormFeedback>{error}</FormFeedback>}
            </FormGroup>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>Close</Button>
          <Button color="primary" onClick={submitForm}>Verify</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default EmailModal;

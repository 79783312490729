// import React, { useState } from "react";
// import {
//   Modal,
//   ModalHeader,
//   ModalBody,
//   ModalFooter,
//   Button,
//   Input,
//   Label,
//   Nav,
//   NavItem,
//   NavLink,
// } from "reactstrap";
// import styles from "./wallet-modal.module.scss";
// import { setCookie, setToken } from "assistance/user_service";
// import { loginUser } from "assistance/identity_service";
// import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import classnames from "classnames";

// interface OTPModalProps {
//   isOpen: boolean;
//   toggle: () => void;
// }

// const WalletOTP: React.FC<OTPModalProps> = ({ isOpen, toggle }) => {
//   const [activeTab, setActiveTab] = useState<string | number>("Account");

//   const currentUser = {
//     name: "Henry Wells",
//     isActive: true,
//   };

//   // change tab
//   const toggleTab = (tab: any) => {
//     if (activeTab !== tab) {
//       setActiveTab(tab);
//     }
//   };

//     const handleAccount = (event: React.MouseEvent<HTMLButtonElement>) => {
//       event.preventDefault();
//       debugger
//       const formData = new FormData();

//       const accountType = document.querySelector<HTMLInputElement>("[name='accountType']")?.value || "";
//       const accountNumber = document.querySelector<HTMLInputElement>("[name='accountNumber']")?.value || "";
//       const confirmAccountNumber = document.querySelector<HTMLInputElement>("[name='confirmAccountNumber']")?.value || "";
//       const ifscCode = document.querySelector<HTMLInputElement>("[name='ifscCode']")?.value || "";
//       const upiNumber = document.querySelector<HTMLInputElement>("[name='UPI']")?.value || "";

//       if (activeTab === "Account") {
//         formData.append("accountType", accountType);
//         formData.append("accountNumber", accountNumber);
//         formData.append("confirmAccountNumber", confirmAccountNumber);
//         formData.append("ifscCode", ifscCode);
//       } else if (activeTab === "UPI") {
//         formData.append("upiNumber", upiNumber);
//       }

//       for (let [key, value] of formData.entries()) {
//         console.log(`${key}: ${value}`);
//       }
//     };

//   return (
//     <>
//       <ToastContainer />

//       <Modal isOpen={isOpen} toggle={toggle} className={`mt-5 ${styles.modal}`}>
//         <ModalBody>
//           <form className="needs-validation"  >
//             <div className="row ">
//               <div className="col-md-12">
//                 <div className="mb-3">
//                   <label className="form-label">Payment Type</label>

//                   <Nav pills justified>
//                     <NavItem>
//                       <NavLink
//                         className={`${classnames({
//                           active: activeTab === "Account",
//                         })} `}
//                         style={{
//                           backgroundColor:
//                             activeTab === "Account" ? "#642e5c" : "white",
//                           border: "1px solid #000",
//                         }}
//                         onClick={() => {
//                           toggleTab("Account");
//                         }}
//                       >
//                         <i className="bx bx-chat font-size-30 d-sm-none" />
//                         <span className="d-none d-sm-block">
//                           Account Number
//                         </span>
//                       </NavLink>
//                     </NavItem>
//                     <NavItem>
//                       <NavLink
//                         className={classnames({ active: activeTab === "UPI" })}
//                         style={{
//                           backgroundColor:
//                             activeTab === "UPI" ? "#642e5c" : "white",
//                           border: "1px solid #000",
//                         }}
//                         onClick={() => {
//                           toggleTab("UPI");
//                         }}
//                       >
//                         <i className="bx bx-group font-size-30 d-sm-none" />
//                         <span className="d-none d-sm-block">UPI ID</span>
//                       </NavLink>
//                     </NavItem>
//                   </Nav>
//                 </div>
//               </div>
//             </div>

//             {activeTab === "Account" ?(
//               <>
//                <div className="row">
//               <div className="col-md-12">
//                 <div className="mb-3">
//                   <label className="form-label">Account Type</label>

//                   <div className="input-group">
//                     <select className="form-select">
//                       <option>Account Type </option>
//                       <option> Current Account </option>
//                       <option>Savng Account</option>
//                     </select>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="row">
//               <div className="col-md-12">
//                 <div className="mb-3">
//                   <label className="form-label">Account Number</label>
//                   <input
//                     name="accountNumber"
//                     placeholder="Account Number"
//                     type="text"
//                     className={`form-control
//                       `}
//                   />
//                 </div>
//               </div>
//             </div>
//             <div className="row">
//               <div className="col-md-12">
//                 <div className="mb-3">
//                   <label className="form-label">Confirm Account Number</label>
//                   <input
//                     name="accountNumber"
//                     placeholder="Confirm Account Number"
//                     type="text"
//                     className={`form-control
//                       `}
//                   />
//                 </div>
//               </div>
//             </div>{" "}
//             <div className="row">
//               <div className="col-md-12">
//                 <div className="mb-3">
//                   <label className="form-label">IFSC Code</label>
//                   <input
//                     name="accountNumber"
//                     placeholder="Account Number"
//                     type="text"
//                     className={`form-control
//                       `}
//                   />
//                 </div>
//               </div>
//             </div>

//               </>
//             ):(

//               <>
//                   <div className="row">
//               <div className="col-md-12">
//                 <div className="mb-3">
//                   <label className="form-label">UPI Number</label>
//                   <input
//                     name="UPI"
//                     placeholder="UPI"
//                     type="text"
//                     className={`form-control
//                       `}
//                   />
//                 </div>
//               </div>
//             </div>
//               </>
//             )}

//             <div className="text-center">
//               <Button type="button" style={{ backgroundColor: "#642e5c" }}
//               onClick={handleAccount}
//               >
//                 Add Account
//               </Button>
//             </div>
//           </form>
//         </ModalBody>
//         {/* <ModalFooter>
//         <Button color="secondary" onClick={toggle}>Close</Button>
//         <Button color="primary" onClick={submitForm}>Verify</Button>
//       </ModalFooter> */}
//       </Modal>
//     </>
//   );
// };

// export default WalletOTP;

import React, { useEffect, useState } from "react";
import { Modal, ModalBody, Button, Nav, NavItem, NavLink } from "reactstrap";
import styles from "./wallet-modal.module.scss";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import classnames from "classnames";
import { addpaymethod } from "assistance/promoterClient_service";

interface OTPModalProps {
  isOpen: boolean;
  toggle: () => void;
  getpaymentmethod: () => void;

}

const WalletOTP: React.FC<OTPModalProps> = ({ isOpen, toggle,getpaymentmethod }) => {
  const [activeTab, setActiveTab] = useState<string | number>("Account");
  const [type, setType] = useState<string>("BA");
  const [code, setCode] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [ifscCodeflag, setIfscCodeflag] = useState(false);
  const [toast2, setToast2] = useState<boolean>(false);

  const [formData, setFormData] = useState({
    MICR: "",
    BRANCH: "",
    ADDRESS: "",
    STATE: "",
    CONTACT: "",
    BANK: "",
    BANKCODE: "",
    CENTRE: "",
    CITY: "",
    DISTRICT: "",
    IFSC: "",
  });

  const [data, setData] = useState(null);

  const [methodName, setmethodName] = useState<string>("Bank Account");
  // change tab function
  const toggleTab = (tab: any) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const defaultDetails = (ifscCode) => {
    fetch(`https://ifsc.razorpay.com/${ifscCode}`) // Replace with your API URL
      .then((response) => {
        if (!response.ok) {
          // Clear form data if the response is not OK (status not 200)
          setFormData({
            MICR: "",
            BRANCH: "",
            ADDRESS: "",
            STATE: "",
            CONTACT: "",
            BANK: "",
            BANKCODE: "",
            CENTRE: "",
            CITY: "",
            DISTRICT: "",
            IFSC: "",
          });
          setIfscCodeflag(false);

          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Ensure that data has the expected keys before updating state
        if (data) {
          setFormData({
            MICR: data.MICR || "",
            BRANCH: data.BRANCH || "",
            ADDRESS: data.ADDRESS || "",
            STATE: data.STATE || "",
            CONTACT: data.CONTACT || "",
            BANK: data.BANK || "",
            BANKCODE: data.BANKCODE || "",
            CENTRE: data.CENTRE || "",
            CITY: data.CITY || "",
            DISTRICT: data.DISTRICT || "",
            IFSC: data.IFSC || "",
          });
          setIfscCodeflag(true);
        }
      })
      .catch((error) => {
        console.error("Error fetching IFSC details:", error);
        // Optional: Additional error handling if needed
      });
  };

  useEffect(() => {
    if (ifscCode) {
      defaultDetails(ifscCode);
    }
  }, [ifscCode]);

  // handle form data collection and submission
  const handleAccount = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault(); // Prevent form submission default behavior
    setToast2(true)
    let isValid = true; // Track validation status
    let errors: { [key: string]: string } = {}; // Store error messages for fields
    const formData = new FormData(); // Initialize formData

    // Function for validation
    const validateAccount = () => {
      const accountType =
        document.querySelector<HTMLInputElement>("[name='accountType']")
          ?.value || "";
      const accountNumber =
        document.querySelector<HTMLInputElement>("[name='accountNumber']")
          ?.value || "";
      const confirmAccountNumber =
        document.querySelector<HTMLInputElement>(
          "[name='confirmAccountNumber']"
        )?.value || "";
      const ifscCode =
        document.querySelector<HTMLInputElement>("[name='ifscCode']")?.value ||
        "";

      const accountRegex = /^\d{9,18}$/; // Account number should be between 9 and 18 digits
      const ifscRegex = /^[A-Za-z]{4}\d{0,1}[A-Za-z0-9]{6}$/; // IFSC code format: ABCD0123456
      // Validation checks
      if (!accountType) {
        isValid = false;
        errors.accountType = "Account type is required.";
      }
      if (!accountNumber || !accountRegex.test(accountNumber)) {
        isValid = false;
        errors.accountNumber =
          "Invalid account number. It should be between 9 and 18 digits.";
      }
      if (!confirmAccountNumber || accountNumber !== confirmAccountNumber) {
        isValid = false;
        errors.accountMatch =
          "Account number and confirm account number must match.";
      }
      if (ifscCodeflag === false) {
        isValid = false;
        errors.ifscCode = "Invalid IFSC code.";
      } else {
        setCode(ifscCode);
      }
      // Append data to form
    


            formData.append(
        "accountType",
        accountType === "Current Account" ? "Current" : "Savings"
      );
      formData.append("accountNumber", accountNumber);
      formData.append("confirmAccountNumber", confirmAccountNumber);
      formData.append("ifscCode", ifscCode);
    };

    const validateUpi = () => {
      const upiNumber =
        document.querySelector<HTMLInputElement>("[name='UPI']")?.value || "";
      const upiRegex = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9.-]+$/; // UPI ID validation

      if (!upiNumber || !upiRegex.test(upiNumber)) {
        isValid = false;
        errors.upiNumber =
          "Invalid UPI number. It should follow the format 'username@upi'.";
      }

      formData.append("upiNumber", upiNumber);
    };

    // Perform validation based on active tab
    if (activeTab === "Account") {
      setType("BA");
      setmethodName("Bank Account");
      validateAccount();
    } else if (activeTab === "UPI") {
      setType("UPIID");
      setmethodName("UPI ID")
      validateUpi();
    }

    // If validation fails, show error messages
    if (!isValid) {
      setErrors(errors); // Set errors to state
      return;
    }

    // Log form data values for verification
    formData.forEach((value, key) => {
      // console.log(`${key}: ${value}`);
    });
   
    if (isValid) {
      try {
        const response = await addpaymethod(
          activeTab === "Account" ? 'BA':'UPI',
          activeTab === "Account" ? 'Bank Account':'UPI ID',
          formData.get("accountType") || "",
          formData.get("accountNumber") || "",
          formData.get("upiNumber") || "",
          formData.get("ifscCode") || ""
        );

        if (response.data && response.status === 200) {
          setFormData({
            MICR: "",
            BRANCH: "",
            ADDRESS: "",
            STATE: "",
            CONTACT: "",
            BANK: "",
            BANKCODE: "",
            CENTRE: "",
            CITY: "",
            DISTRICT: "",
            IFSC: "",
          });
          getpaymentmethod()
          toggle()
          toast.success("Account added successfully!", { autoClose: 2000 });
          setToast2(false)
        } else if (response.status === 203) {
          console.warn(
            "Received status 203: Possible warning or different condition."
          );
        } else {
          console.error("Unexpected response status:", response.status);
        }
      } catch (err) {
        console.error("Error adding payment method:", err);
      }
    }
  };

  // Assuming you're using state to manage errors
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setIfscCode(value);

    // Example validation (IFSC code validation can be more complex)
    if (name === "ifscCode") {
      if (!value.match(/^[A-Za-z]{4}[0-9]{7}$/)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          ifscCode: "Invalid IFSC code",
        }));
      } else {
        setErrors((prevErrors) => {
          const { ifscCode, ...rest } = prevErrors;
          return rest;
        });
      }
    }
  };





  return (
    <>
      <ToastContainer />
      <Modal isOpen={isOpen} toggle={toggle} className={`mt-5 ${styles.modal}`}>
        <ModalBody>
          <div className="row">
            <div className="col-md-12">
              <div className="mb-3">
                <label className="form-label">Payment Type</label>
                <Nav pills justified>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === "Account",
                      })}
                      style={{
                        backgroundColor:
                          activeTab === "Account" ? "#642e5c" : "white",
                        border: "1px solid #000",
                      }}
                      onClick={() => toggleTab("Account")}
                    >
                      <span className="d-none d-sm-block">Account Number</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "UPI" })}
                      style={{
                        backgroundColor:
                          activeTab === "UPI" ? "#642e5c" : "white",
                        border: "1px solid #000",
                      }}
                      onClick={() => toggleTab("UPI")}
                    >
                      <span className="d-none d-sm-block">UPI ID</span>
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
            </div>
          </div>

          <div>
            <form>
              {activeTab === "Account" && (
                <>
                  {" "}
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">Account Type</label>
                        <div className="input-group">
                          <select className="form-select" name="accountType">
                            <option>Select Account Type</option>
                            <option>Current Account</option>
                            <option>Saving Account</option>
                          </select>

                          {errors.accountType && (
                            <div className={styles["error-message"]}>
                              {errors.accountType}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {" "}
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">Account Number</label>

                        <input
                          className={`form-control    `}
                          placeholder="Enter Account Number"
                          name="accountNumber"
                          
                          type="text"
                          onCopy={(e) => e.preventDefault()}
                          // onPaste={(e) => e.preventDefault()}
                           autoComplete="off"
                        />
                        {errors.accountNumber && (
                          <div className={styles["error-message"]}>
                            {errors.accountNumber}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {" "}
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">
                          Confirm Account Number
                        </label>
                        <input
                          className={`form-control    `}
                          placeholder="Enter Confirm Account Number"
                          name="confirmAccountNumber"
                          type="text"
                           autoComplete="off"
                          onCopy={(e) => e.preventDefault()}
                          onPaste={(e) => e.preventDefault()}
                        />
                        {errors.confirmAccountNumber && (
                          <div className={styles["error-message"]}>
                            {errors.confirmAccountNumber}
                          </div>
                        )}
                        {errors.accountMatch && (
                          <div className={styles["error-message"]}>
                            {errors.accountMatch}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {" "}
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">IFSC Code</label>
                        <input
                          className={`form-control    `}
                          placeholder="Enter IFSC Code"
                          name="ifscCode"
                          type="text"
                           autoComplete="off"
                          value={ifscCode}
                          onChange={handleChange}
                        />
                        {errors.ifscCode && (
                          <div className={styles["error-message"]}>
                            {errors.ifscCode}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}

              {activeTab === "UPI" && (
                <div className="row">
                  {" "}
                  <div className="col-md-12">
                    <div className="mb-3">
                      {" "}
                      <label className="form-label">UPI </label>
                      <input
                        name="UPI"
                        className={`form-control    `}
                        placeholder="Enter UPI "
                        type="text"
                        onCopy={(e) => e.preventDefault()}
                        onPaste={(e) => e.preventDefault()}
                        autoComplete="off"
                      />
                      {errors.upiNumber && (
                        <div className={styles["error-message"]}>
                          {errors.upiNumber}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              <div className="text-center">
                <Button
                  type="button"
                  aria-disabled={toast2 === true}
                  style={{ backgroundColor: "#642e5c" }}
                  onClick={handleAccount}
                >
                  Submit
                </Button>
              </div>
            </form>
            <div className="row">
              <div className="col-md-6">
                <div className="mb-3 mt-5">
                  <b>{formData?.BANK}</b>
                  <p>{formData?.ADDRESS}</p>
                  {formData?.CONTACT ? (
                    <span>Phone : {formData?.CONTACT}</span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default WalletOTP;

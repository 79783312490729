import React, { useEffect, useState } from "react";
import styles from "./Register.module.scss";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Form,
  Input,
  Label,
  FormFeedback,
  Alert,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
// import {
//   loginuser,
//   resetLoginMsgFlag,
//   socialLogin,
// } from "slices/auth/login/thunk";
// import IdentityService from 'path-to-your-identity-service'; // Adjust import path as necessary
// import UserService from 'path-to-your-user-service'; // Adjust import path as necessary
// import toast from 'path-to-your-toast-library'; // Adjust import path as necessary
import withRouter from "Components/Common/withRouter";
import { createSelector } from "reselect";
import { isAuthenticated } from "assistance/user_service";
import { forgetPassword, loginUser } from "assistance/identity_service";
import LoginOTPModal from "Components/login-otp/login-otp";
import passwordLogin, { handleLogin } from "../Authentication/logins"; // Import your JS functions
import { fetchCountryList } from "./Registerfunctions";
import LoginOTPModalPhone from "Components/login-otp-Phone/login-otp-phone";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoginOTPModalForgot from "Components/login-otp-forgot/login-otp-forgot";

interface Country {
  id: number;
  sortname: string;
  name: string;
  phoneCode: number;
}

const Login = (props: any) => {
  const [show, setShow] = useState(false);
  const [shows, setShows] = useState(false);
  const [sho, setSho] = useState(false);

  const [em, setem] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  // const [userData, setUserData] = useState(null);
  const [showOTP, setShowOTP] = useState(false);
  const [showOTPs, setShowOTPs] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
  });

  const [formDatas, setFormDatas] = useState({
    phone: "",
  });

  const dispatch = useDispatch();
  const [identityType, setIdentityType] = useState("EMAIL");
  const [selectedCode, setSelectedCode] = useState("91");
  const [countryList, setCountryList] = useState<Country[]>([]);
  const [showPasswordOTP, setShowPasswordOTP] = useState(false);

  useEffect(() => {
    const value = sessionStorage.getItem("datas");
    if (value) {
      try {
        const parsedValue = JSON.parse(value);

        setFormData({
          email: parsedValue?.email || "",
        });
        toast.success("You have already Account Please login");
      } catch (error) {
        console.error("Error parsing sessionStorage data:", error);
      }
    }
  }, []);

  useEffect(() => {
    const getCountries = async () => {
      try {
        const countries = await fetchCountryList(); // Call the function from JS file
        setCountryList(countries);
      } catch (err) {
        console.error("Error fetching countries:", err);
      } finally {
        setLoading(false);
      }
    };

    getCountries();
  }, []);

  document.title = " Skartio - Insider";

  const selectProperties = createSelector(
    (state: any) => state.Login,
    (login) => ({
      error: login.error,
    })
  );

  const handleCodeChange = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSelectedCode(e.target.value);
  };
  const { error } = useSelector(selectProperties);

  // Form validation
  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: formData?.email || "",
      password: "",
      phone: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email format")
        .required("Please Enter Your email"),

      // password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: async (values: any) => {
      // if (isSubmitting) return; // Prevent multiple submissions
      // setIsSubmitting(true);
      // console.log(values)

      sessionStorage.setItem("emailID", values.email);

      const emailValue = values.email;
      const passwordValue = values.password;
      const phoneValue = values.phone;
      const passwordEncoded = btoa(passwordValue);
      setFormData({
        email: emailValue,
      });
      setFormDatas({
        phone: { selectedCode } + "-" + { phoneValue },
      });

      if (identityType === "EMAIL") {
        loginUser(
          "01",
          emailValue,
          "",
          "EMAIL",
          "", // Pass the joined OTP string here
          "U3"
        ).then((response) => {
          if (response.status === 200) {
            // router.push({
            //   pathname: "/account/login/",
            //   query: {
            //     inputValue: emailValue,
            //     identityType: "EMAIL",
            //   },
            // });

            setShowOTP(true);
            //console.log(response);
          } else if (response.status === 202) {
            toast.error("You don't have an account, please register");

            setTimeout(() => {
              window.location.href = "/register";
            }, 2000);

            //setShowOTP(true);
          }
          //console.log(response);
        });
      } else if (identityType === "PASSWORD") {
        // console.log('gdgfjsfgjfhdgj')

        passwordLogin(emailValue, passwordEncoded, setShows,setSho);

        if (shows === true) {
          toast.error("Account Login failed Please try again");
        }
           } else if (identityType === "PHONE") {
        // debugger
        //  loginUser(
        //     "01",
        //     "+"+selectedCode+"-"+phoneValue,
        //     "",
        //     "PHONE",
        //     "", // Pass the joined OTP string here
        //     "U3"
        //   ).then((response) => {
        //   if (response.status === 200) {
        //     setShowOTPs(true);
        //   } else if (response.status === 202) {
        //     // toast("You don't have an account, please register");
        //     // setTimeout(() => {
        //     //  window.location.href = "/register"
        //     // }, 2000);
        //   }
        // })
        // handleLogin(selectedCode,phoneValue,setShowOTPs)
      }
    },
  });

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        // dispatch(resetLoginMsgFlag());
      }, 3000);
    }
  }, [dispatch, error]);

  useEffect(() => {
    var isFetching = true;
    if (isFetching) {
      if (isAuthenticated()) {
        // router.push("/dashboard");
        window.location.href = "/dashboard";
      } else {
      }
    }
    return () => {
      isFetching = false;
    };
  }, []);

  const otpForPassword = (e: React.MouseEvent<HTMLParagraphElement, MouseEvent>) => {
    debugger
    e.preventDefault();

    const emailv = validation.values.email;
    setem(emailv)
    // Save the email in sessionStorage
    sessionStorage.setItem("emailID", emailv);
  
    if (emailv && emailv !== "Enter Valid Email Address" && emailv !== "") {
      forgetPassword(emailv, "", "EMAIL", "", "U3")
        .then((response) => {
          if (response.status === 200) {
            setShowPasswordOTP(true);
          } else {
            setShowPasswordOTP(false);
            setTimeout(() => {
              toast.error("Please Try again later");
            }, 1000);
          }
        })
        .catch((error) => {
          console.error("Error in forgetPassword:", error);
          // setShowPasswordOTP(false);
          toast.error("Error while requesting OTP");
        });
    }else{
      toast.error("Enter Email Id while requesting OTP");

    }
  };
  useEffect(()=>{
  
    if (shows === true) {
      toast.error("Invalid Password, Please Try again ");
    }
  },[shows])
  
  // useEffect(()=>{
  //   if (sho === true) {
  //      toast.success("Login Successfully");
  //   }
  // },[sho])
  

  
  return (
    <React.Fragment>
      <ToastContainer />
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div style={{ backgroundColor: "#642e5c" }}>
                  <Row>
                    <Col className="col-7">
                      <div className="text-white p-4">
                        <h5 className="text-white">Welcome Back!</h5>
                        <p>Sign in to continue to Skartio Insider.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={""} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="auth-logo">
                    <Link
                      to="https://insider.skartio.com/"
                      className="auth-logo-light"
                    >
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src="https://skartiocloud.sgp1.cdn.digitaloceanspaces.com/SKARTIO1/THEME/1728039227079-download.png"
                            alt="Insider"
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>

                  {identityType === "EMAIL" ? (
                    <div className="p-2">
                      <Form
                        className="form-horizontal"
                        onSubmit={validation.handleSubmit}
                      >
                        <div className="mb-3 mt-4">
                          {error && <Alert color="danger">{error}</Alert>}
                          <Label className="form-label">Email</Label>
                          <Input
                            name="email"
                            className="form-control"
                            placeholder="Enter email"
                            autoComplete="off"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email &&
                              Boolean(validation.errors.email)
                            }
                          />
                          {validation.touched.email &&
                            validation.errors.email && (
                              <FormFeedback>
                                {validation.errors.email}
                              </FormFeedback>
                            )}
                        </div>

                        {/* <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <div className="input-group auth-pass-inputgroup">
                          <Input
                            name="password"
                            value={validation.values.password || ""}
                            type={show ? "text" : "password"}
                            placeholder="Enter Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={validation.touched.password && Boolean(validation.errors.password)}
                          />
                          <button
                            onClick={() => setShow(!show)}
                            className="btn btn-light"
                            type="button"
                            id="password-addon"
                          >
                            <i className="mdi mdi-eye-outline"></i>
                          </button>
                        </div>
                        {validation.touched.password && validation.errors.password && (
                          <FormFeedback>{validation.errors.password}</FormFeedback>
                        )}
                      </div> */}

                        <div className="mt-4 d-grid">
                          <button
                            className="btn text-white btn-block"
                            type="submit"
                            style={{ backgroundColor: "#642e5c" }}
                            // disabled={isSubmitting}
                          >
                            Get OTP
                          </button>
                        </div>

                        <div className="mt-4 d-grid">
                          <button
                            className="btn text-dark btn-block"
                            type="submit"
                            style={{
                              backgroundColor: "white",
                              border: "1px solid #642e5c",
                            }}
                            onClick={() => setIdentityType("PASSWORD")}
                          >
                            Use Password Login
                          </button>
                        </div>
                        {/* <div className="mt-4 d-grid">
                          <button
                            className="btn text-dark btn-block"
                            type="submit"
                            style={{
                              backgroundColor: "white",
                              border: "1px solid #642e5c",
                            }}
                            onClick={() => setIdentityType("PHONE")}
                          >
                            Use Phone Login
                          </button>
                        </div> */}
                      </Form>
                    </div>
                  ) : identityType === "PHONE" ? (
                    <>
                      <Form
                        className="form-horizontal"
                        onSubmit={validation.handleSubmit}
                      >
                        <div className="mb-3 mt-4 ">
                          <div className="input-group">
                            <select
                              className="form-select"
                              value={selectedCode}
                              onChange={handleCodeChange} // Ensure this is being handled properly
                              style={{ maxWidth: "70px" }}
                            >
                              {countryList &&
                                countryList.map((country, index) => (
                                  <option
                                    key={index}
                                    value={country.phoneCode.toString()}
                                  >
                                    {country.name}
                                  </option>
                                ))}
                            </select>
                            <Input
                              className="form-control"
                              type="tel"
                              name="phone"
                              autoComplete="off"
                              // value={formData.phone || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              placeholder="Enter phone number"
                              invalid={
                                validation.touched.phone &&
                                validation.errors.phone
                                  ? true
                                  : false
                              }
                            />
                          </div>
                          {validation.touched.phone &&
                            validation.errors.phone && (
                              <FormFeedback type="invalid">
                                {validation.errors.phone}
                              </FormFeedback>
                            )}
                        </div>
                        <div className="mt-4 d-grid">
                          <button
                            className="btn text-white btn-block"
                            type="submit"
                            style={{ backgroundColor: "#642e5c" }}
                            // disabled={isSubmitting}
                          >
                            Get OTP{" "}
                          </button>
                        </div>

                        <div className="mt-4 d-grid">
                          <button
                            className="btn text-dark btn-block"
                            type="submit"
                            style={{
                              backgroundColor: "white",
                              border: "1px solid #642e5c",
                            }}
                            onClick={() => setIdentityType("EMAIL")}
                          >
                            Use Email Login
                          </button>
                        </div>
                        <div className="mt-4 d-grid">
                          <button
                            className="btn text-dark btn-block"
                            type="submit"
                            style={{
                              backgroundColor: "white",
                              border: "1px solid #642e5c",
                            }}
                            onClick={() => setIdentityType("PASSWORD")}
                          >
                            Use Password Login
                          </button>
                        </div>
                      </Form>
                    </>
                  ) : identityType === "PASSWORD" ? (
                    <>
                      <Form
                        className="form-horizontal"
                        onSubmit={validation.handleSubmit}
                      >
                        <div className="mb-3 mt-4">
                          {error && <Alert color="danger">{error}</Alert>}
                          <Label className="form-label">Email</Label>
                          <Input
                            name="email"
                            className="form-control"
                            placeholder="Enter email"
                            type="text"
                            autoComplete="off"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email &&
                              Boolean(validation.errors.email)
                            }
                          />
                          {validation.touched.email &&
                            validation.errors.email && (
                              <FormFeedback>
                                {validation.errors.email}
                              </FormFeedback>
                            )}
                        </div>

                        <div className="mb-3 mt-4">
                          <Label className="form-label">Password</Label>
                          <div className="input-group auth-pass-inputgroup">
                            <Input
                              name="password"
                              value={validation.values.password || ""}
                              type={show ? "text" : "password"}
                              placeholder="Enter Password"
                              autoComplete="off"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.password &&
                                Boolean(validation.errors.password)
                              }
                            />
                            <button
                              onClick={() => setShow(!show)}
                              className="btn btn-light"
                              type="button"
                              id="password-addon"
                            >
                              <i className="mdi mdi-eye-outline"></i>
                            </button>
                          </div>
                          {validation.touched.password &&
                            validation.errors.password && (
                              <FormFeedback>
                                {validation.errors.password}
                              </FormFeedback>
                            )}
                        </div>
                        <div className={`my-3 ${styles["custom-align"]}`}>
  <p
    onClick={(e) => {
      otpForPassword(e); // Call the otpForPassword function here
      // setShowPasswordOTP(!showPasswordOTP); // Toggle the showPasswordOTP state
    }}
  >
    Forgot Password?
  </p>
</div>

                        <div className="mt-4 d-grid">
                          <button
                            className="btn text-white btn-block"
                            type="submit"
                            style={{ backgroundColor: "#642e5c" }}
                            // disabled={isSubmitting}
                          >
                            Login
                          </button>
                        </div>

                        {/* <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" /> Forgot your password?
                        </Link>
                      </div> */}
                        {/* <span className={styles["ortext"]}>or</span> */}

                        <div className="mt-4 d-grid">
                          <button
                            className="btn text-dark btn-block"
                            type="submit"
                            style={{
                              backgroundColor: "white",
                              border: "1px solid #642e5c",
                            }}
                            onClick={() => setIdentityType("EMAIL")}
                          >
                            Use OTP Login
                          </button>
                        </div>
                        {/* <div className="mt-4 d-grid">
                          <button
                            className="btn text-dark btn-block"
                            type="submit"
                            style={{
                              backgroundColor: "white",
                              border: "1px solid #642e5c",
                            }}
                            onClick={() => setIdentityType("PHONE")}

                          >
                            Use Phone Login
                          </button>
                        </div> */}
                      </Form>
                    </>
                  ) : (
                    ""
                  )}
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Don&apos;t have an account?{" "}
                  <Link to="/register" className="fw-medium text-primary">
                    Signup now
                  </Link>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {showOTP && (
        <LoginOTPModal
          isOpen={showOTP}
          toggle={() => setShowOTP(false)} // Update toggle function
          formData={formData}
        />
      )}

      {showOTPs && (
        <LoginOTPModalPhone
          isOpen={showOTP}
          toggle={() => setShowOTPs(false)} // Update toggle function
          formData={formDatas}
        />
      )}

      {showPasswordOTP && (
        <>
          <LoginOTPModalForgot
            isOpen={showPasswordOTP}
            toggle={() => setShowPasswordOTP(false)} // Update toggle function
            formData={formDatas}
            em={em}
          />
        </>
      )}
    </React.Fragment>
  );
};

export default withRouter(Login);

import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../Components/Common/Breadcrumb";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Container } from "reactstrap";
import {
  deletePromoterPaymentmethod,
  getProfile,
  getPromoterPaymentmethod,
  getRewardList,
  getTranscationSummary,
  setPrimaryAccount,
} from "assistance/promoterClient_service";
import { round } from "lodash";
import styles from "./rewards.module.scss";
import Loader from "Components/loader/loader";

interface ProfileData {
  firstName: string;
  lastName: string;
  Phone: string;
  email: string;
  phone: any;

  // Add other fields if necessary
}

interface RewardData {
  createdAt: string;
  dueAt: string;
  promoterId: string;
  promoterName: string;
  rewardType: string;
  rewardValue: any;
  trnAt: string;
  trnDesc: string;
  trnStatus: string;
}

const Rewards: React.FC = () => {
  document.title = " Skartio - Insider";

  const [currentPage, setCurrentPage] = useState(1);
  const rewardsPerPage = 5; // Customize the number of rewards per page
  const [loader, setLoader] = useState<boolean>(false);

  const [rewardData, setRewardData] = useState<RewardData[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [Pages, setPages] = useState(0);

  const indexOfLastReward = currentPage * rewardsPerPage;
  const indexOfFirstReward = indexOfLastReward - rewardsPerPage;
  const currentRewards = rewardData.slice(
    indexOfFirstReward,
    indexOfLastReward
  );

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };
  const dateFormateDay = (date: Date): string => {
    console.log(date);
    const currentDate = new Date();
    // if (currentDate === date) {
    //   setShowDate(true);
    // } else {
    //   setShowDate(false);
    // }

    const day = date.getDate().toString().padStart(2, "0");
    const month = date.toLocaleString("default", { month: "short" }); // Get full month name
    const year = date.getFullYear();
    const formattedDate = `${month} ${day}, ${year}`;
    return formattedDate; // Return the formatted date
  };

  useEffect(() => {
    const fetchSummary = async () => {
      try {
        const response = await getTranscationSummary();

        if (response.data && response.status === 200) {
          console.log(response.data?.data?.totalCount);
        } else if (response.data && response.status === 203) {
          // window.location.href="/createAccount";
        }
      } catch (error) {
        console.error("Failed to fetch profile data", error);
      }
    };

    fetchSummary();
  }, []);

  useEffect(() => {
    const fetchRewards = async () => {
      try {
        const response = await getRewardList(currentPage, 10);

        if (response.data && response.status === 200) {
          setRewardData(response.data?.data?.data);
          const pages = round(response.data?.data?.totalCount / 5);

          setTotalPages(pages);
          setPages(response.data?.data?.totalCount);
          setLoader(true)
        } else if (response.data && response.status === 203) {
          // window.location.href="/createAccount";
        }
      } catch (error) {
        console.error("Failed to fetch profile data", error);
      }
    };

    fetchRewards();
  }, [currentPage]);

  const dateFormate = (date: Date): string => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = date.toLocaleString("default", { month: "long" }); // Get full month name
    const year = date.getFullYear();
    const formattedDate = `${month} ${day}, ${year}`;
    return formattedDate; // Return the formatted date
  };

  return (

    
    <React.Fragment>
      <ToastContainer />
      {loader === false ?  (
        <Loader/>
       ):(
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Rewards " breadcrumbItem="Rewards" />
          <div className="row">
            {/* {rewardData &&
              rewardData.map((item, index) => {
                return (
                  <div className="col-12 col-xl-6 col-lg-12 col-sm-12 col-md-12">
                    <div className="card shadow">
                      <div className="card-body">
                        

                        <h4 className="mb-4 d-flex card-title">{item.rewardType === "FP" ? (
                          <span className="me-3 w-10">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                              width={30}
                              height={30}
                              fill="#642e5c"
                            >
                              <path d="M512 80c0 18-14.3 34.6-38.4 48c-29.1 16.1-72.5 27.5-122.3 30.9c-3.7-1.8-7.4-3.5-11.3-5C300.6 137.4 248.2 128 192 128c-8.3 0-16.4 .2-24.5 .6l-1.1-.6C142.3 114.6 128 98 128 80c0-44.2 86-80 192-80S512 35.8 512 80zM160.7 161.1c10.2-.7 20.7-1.1 31.3-1.1c62.2 0 117.4 12.3 152.5 31.4C369.3 204.9 384 221.7 384 240c0 4-.7 7.9-2.1 11.7c-4.6 13.2-17 25.3-35 35.5c0 0 0 0 0 0c-.1 .1-.3 .1-.4 .2c0 0 0 0 0 0s0 0 0 0c-.3 .2-.6 .3-.9 .5c-35 19.4-90.8 32-153.6 32c-59.6 0-112.9-11.3-148.2-29.1c-1.9-.9-3.7-1.9-5.5-2.9C14.3 274.6 0 258 0 240c0-34.8 53.4-64.5 128-75.4c10.5-1.5 21.4-2.7 32.7-3.5zM416 240c0-21.9-10.6-39.9-24.1-53.4c28.3-4.4 54.2-11.4 76.2-20.5c16.3-6.8 31.5-15.2 43.9-25.5l0 35.4c0 19.3-16.5 37.1-43.8 50.9c-14.6 7.4-32.4 13.7-52.4 18.5c.1-1.8 .2-3.5 .2-5.3zm-32 96c0 18-14.3 34.6-38.4 48c-1.8 1-3.6 1.9-5.5 2.9C304.9 404.7 251.6 416 192 416c-62.8 0-118.6-12.6-153.6-32C14.3 370.6 0 354 0 336l0-35.4c12.5 10.3 27.6 18.7 43.9 25.5C83.4 342.6 135.8 352 192 352s108.6-9.4 148.1-25.9c7.8-3.2 15.3-6.9 22.4-10.9c6.1-3.4 11.8-7.2 17.2-11.2c1.5-1.1 2.9-2.3 4.3-3.4l0 3.4 0 5.7 0 26.3zm32 0l0-32 0-25.9c19-4.2 36.5-9.5 52.1-16c16.3-6.8 31.5-15.2 43.9-25.5l0 35.4c0 10.5-5 21-14.9 30.9c-16.3 16.3-45 29.7-81.3 38.4c.1-1.7 .2-3.5 .2-5.3zM192 448c56.2 0 108.6-9.4 148.1-25.9c16.3-6.8 31.5-15.2 43.9-25.5l0 35.4c0 44.2-86 80-192 80S0 476.2 0 432l0-35.4c12.5 10.3 27.6 18.7 43.9 25.5C83.4 438.6 135.8 448 192 448z" />
                            </svg>
                          </span>
                        ) : (
                          <><span className="me-3 w-10">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" width={30} fill="#642e5c"
                              height={30}><path d="M96 96l0 224c0 35.3 28.7 64 64 64l416 0c35.3 0 64-28.7 64-64l0-224c0-35.3-28.7-64-64-64L160 32c-35.3 0-64 28.7-64 64zm64 160c35.3 0 64 28.7 64 64l-64 0 0-64zM224 96c0 35.3-28.7 64-64 64l0-64 64 0zM576 256l0 64-64 0c0-35.3 28.7-64 64-64zM512 96l64 0 0 64c-35.3 0-64-28.7-64-64zM288 208a80 80 0 1 1 160 0 80 80 0 1 1 -160 0zM48 120c0-13.3-10.7-24-24-24S0 106.7 0 120L0 360c0 66.3 53.7 120 120 120l400 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-400 0c-39.8 0-72-32.2-72-72l0-240z"/></svg>
                              </span>
                              </>
                        )}{item?.trnDesc}</h4>

                        <div className="mt-4">
                          <div className="mb-2 row">
                            <div className={`col-10`}>
                              <div
                                className="d-flex justify-content-start "
                                style={{
                                  fontSize: "15px",
                                  flexDirection: "column",
                                }}
                              >
                                <p>
                                  Received On :{" "}
                                  {dateFormate(new Date(item?.createdAt))}{" "}
                                </p>

                                <p>
                                  Due On : {dateFormate(new Date(item?.dueAt))}
                                </p>
                              </div>
                            </div>
                            <div className={`col-2`}>
                              <div
                                className="d-flex justify-content-end  "
                                style={{ fontSize: "20px" }}
                              >
                                <b>
                                  {item.rewardType === "FC" ? (
                                    <>
                                      <span className=" avatar-title rounded-circle bg-warning-subtle text-warning  p-2"><i className="mdi mdi-currency-inr"></i>   {" "}                                    {item.rewardValue}
                                     </span>
                                    </>
                                  ) : (
                                    `${item.rewardValue}`
                                  )}
                                </b>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}

{Pages > 6 && (
  <div className={styles.pagination}>
    {[...Array(totalPages)].map((_, index) => (
      <button
        key={`page-${index}`}
        onClick={() => handlePageChange(index + 1)}
        className={`${styles['page-item']} ${
          currentPage === index + 1 ? styles.active : ''
        }`}
      >
        {index + 1}
      </button>
    ))}
  </div>
)} */}
            <div className="col-xl-12">
              <div className="">
                <div className="card-body">
                  {/* <div className="h4 card-title">Table head</div>
      <div className="card-title-desc card-subtitle">Use one of two modifier className to make <code>&lt;thead&gt;</code>s appear light or dark gray.</div> */}
                  <div className="table-responsive">
                    <table className="table mb-0 table">
                      <thead className="table-light">
                        <tr>
                          <th>#</th>
                          <th>Reward </th>
                          <th>Received On</th>
                          <th> Due On</th>
                          <th className={`${styles["texts"]}`}>
                            Reward Amount
                          </th>
                          <th className={`${styles["texts"]}`}>Status </th>
                        </tr>
                      </thead>
                      <tbody>
                        {rewardData &&
                          rewardData.map((item, index) => {
                            return (
                              <tr>
                                <th scope="row">
                                  {item.rewardType === "FP" ? (
                                    <span className="me-3 w-10">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 512 512"
                                        width={30}
                                        height={30}
                                        fill="#642e5c"
                                      >
                                        <path d="M512 80c0 18-14.3 34.6-38.4 48c-29.1 16.1-72.5 27.5-122.3 30.9c-3.7-1.8-7.4-3.5-11.3-5C300.6 137.4 248.2 128 192 128c-8.3 0-16.4 .2-24.5 .6l-1.1-.6C142.3 114.6 128 98 128 80c0-44.2 86-80 192-80S512 35.8 512 80zM160.7 161.1c10.2-.7 20.7-1.1 31.3-1.1c62.2 0 117.4 12.3 152.5 31.4C369.3 204.9 384 221.7 384 240c0 4-.7 7.9-2.1 11.7c-4.6 13.2-17 25.3-35 35.5c0 0 0 0 0 0c-.1 .1-.3 .1-.4 .2c0 0 0 0 0 0s0 0 0 0c-.3 .2-.6 .3-.9 .5c-35 19.4-90.8 32-153.6 32c-59.6 0-112.9-11.3-148.2-29.1c-1.9-.9-3.7-1.9-5.5-2.9C14.3 274.6 0 258 0 240c0-34.8 53.4-64.5 128-75.4c10.5-1.5 21.4-2.7 32.7-3.5zM416 240c0-21.9-10.6-39.9-24.1-53.4c28.3-4.4 54.2-11.4 76.2-20.5c16.3-6.8 31.5-15.2 43.9-25.5l0 35.4c0 19.3-16.5 37.1-43.8 50.9c-14.6 7.4-32.4 13.7-52.4 18.5c.1-1.8 .2-3.5 .2-5.3zm-32 96c0 18-14.3 34.6-38.4 48c-1.8 1-3.6 1.9-5.5 2.9C304.9 404.7 251.6 416 192 416c-62.8 0-118.6-12.6-153.6-32C14.3 370.6 0 354 0 336l0-35.4c12.5 10.3 27.6 18.7 43.9 25.5C83.4 342.6 135.8 352 192 352s108.6-9.4 148.1-25.9c7.8-3.2 15.3-6.9 22.4-10.9c6.1-3.4 11.8-7.2 17.2-11.2c1.5-1.1 2.9-2.3 4.3-3.4l0 3.4 0 5.7 0 26.3zm32 0l0-32 0-25.9c19-4.2 36.5-9.5 52.1-16c16.3-6.8 31.5-15.2 43.9-25.5l0 35.4c0 10.5-5 21-14.9 30.9c-16.3 16.3-45 29.7-81.3 38.4c.1-1.7 .2-3.5 .2-5.3zM192 448c56.2 0 108.6-9.4 148.1-25.9c16.3-6.8 31.5-15.2 43.9-25.5l0 35.4c0 44.2-86 80-192 80S0 476.2 0 432l0-35.4c12.5 10.3 27.6 18.7 43.9 25.5C83.4 438.6 135.8 448 192 448z" />
                                      </svg>
                                    </span>
                                  ) : (
                                    <>
                                      <span className="me-3 w-10">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 640 512"
                                          width={30}
                                          fill="#642e5c"
                                          height={30}
                                        >
                                          <path d="M96 96l0 224c0 35.3 28.7 64 64 64l416 0c35.3 0 64-28.7 64-64l0-224c0-35.3-28.7-64-64-64L160 32c-35.3 0-64 28.7-64 64zm64 160c35.3 0 64 28.7 64 64l-64 0 0-64zM224 96c0 35.3-28.7 64-64 64l0-64 64 0zM576 256l0 64-64 0c0-35.3 28.7-64 64-64zM512 96l64 0 0 64c-35.3 0-64-28.7-64-64zM288 208a80 80 0 1 1 160 0 80 80 0 1 1 -160 0zM48 120c0-13.3-10.7-24-24-24S0 106.7 0 120L0 360c0 66.3 53.7 120 120 120l400 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-400 0c-39.8 0-72-32.2-72-72l0-240z" />
                                        </svg>
                                      </span>
                                    </>
                                  )}
                                </th>
                                <td>{item?.trnDesc}</td>
                                <td>
                                  {dateFormate(new Date(item?.createdAt))}
                                </td>
                                <td>{dateFormate(new Date(item?.dueAt))}</td>
                                <td className={`${styles["rewards"]}`}>
                                  {item.rewardType === "FC" ? (
                                    <div className="d-flex justify-content-center">
                                      <i className="mdi mdi-currency-inr"></i>{" "}
                                      {item.rewardValue}
                                    </div>
                                  ) : (
                                    `${item.rewardValue}`
                                  )}
                                </td>

                                <td
  className={`${styles["texts"]} ${
    item.trnStatus === "P"
      ? styles["processing"]
      : item.trnStatus === "I"
      ? styles["initiated"]
      : item.trnStatus === "S"
      ? styles["success"]
      : item.trnStatus === "R"
      ? styles["rejected"]
      : item.trnStatus === "A"
      ? styles["approved"]
      : item.trnStatus === "F"
      ? styles["failed"]
      : item.trnStatus === "H"
      ? styles["hold"]
      : ""
  }`}
>
  <div>
    {/* Message */}
    {item.trnStatus === "P" ? (
      <>Processing</>
    ) : item.trnStatus === "I" ? (
      <>Initiated</>
    ) : item.trnStatus === "S" ? (
      <>Success</>
    ) : item.trnStatus === "R" ? (
      <>Rejected</>
    ) : item.trnStatus === "A" ? (
      <>Approved</>
    ) : item.trnStatus === "F" ? (
      <>Failed</>
    ) : item.trnStatus === "H" ? (
      <>Hold</>
    ) : (
      ""
    )}
  </div>

  <div className="text-dark">
    {/* Date */}

    {item.trnStatus === "P" ? ( 

      <>
      {dateFormateDay(new Date(item?.dueAt))}
      </>
    ):''}
    
  </div>
</td>

                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                    {rewardData.length < 0 && (
                      <div className="d-flex justify-content-center">
                        Currently No record
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {Pages > 10 && (
              <div className={`mt-5 ${styles.pagination}`}>
                {[...Array(totalPages)].map((_, index) => (
                  <button
                    key={`page-${index}`}
                    onClick={() => handlePageChange(index + 1)}
                    className={`${styles["page-item"]} ${
                      currentPage === index + 1 ? styles.active : ""
                    }`}
                  >
                    {index + 1}
                  </button>
                ))}
              </div>
            )}
          </div>
        </Container>
      </div>)}
    </React.Fragment>
  );
};

export default Rewards;

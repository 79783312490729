import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input } from "reactstrap";
import styles from './login-otp.module.scss';
import { setCookie, setToken } from "assistance/user_service";
import { loginUser } from "assistance/identity_service";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import createPromoterClient, { getProfile } from "assistance/promoterClient_service";

interface OTPModalProps {
  isOpen: boolean;
  toggle: () => void;
  formData: any;
}

const LoginOTPModal: React.FC<OTPModalProps> = ({ isOpen, toggle, formData }) => {
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [otpError, setOtpError] = useState(false);

  const [account, setAccount] = useState(false);


  
// useEffect(() => {
//   const fetchProfile = async () => {
//     // try {
//       const response = await getProfile();

//       if (response.data && response.status === 200) {
//         setAccount(false)
//       }else if (response.data && response.status === 203) {
//         setAccount(true)


//       }

      
//     // } catch (error) {
//     //   // console.error('Failed to fetch profile data', error);
//     // }
//   };

//   fetchProfile();
// }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const value = e.target.value;
    if (/^[0-9]?$/.test(value)) { // Allow only single digits
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move to the next input field or go back on delete
      if (value && index < otp.length - 1) {
        (document.getElementById(`otp-input-${index + 1}`) as HTMLInputElement)?.focus();
      } else if (!value && index > 0) {
        (document.getElementById(`otp-input-${index - 1}`) as HTMLInputElement)?.focus();
      }
    }
  };


  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pasteData = e.clipboardData.getData("Text").trim();
    
    if (/^\d{4}$/.test(pasteData)) { // Check if the pasted data is exactly 4 digits
      const newOtp = pasteData.split("").slice(0, 4); // Split into an array, ensure only 4 digits
      setOtp(newOtp);
      setOtpError(false);
      e.preventDefault(); // Prevent the default paste action
  
      // Automatically move focus to the last input field
      setTimeout(() => {
        const lastInput = document.getElementById(`otp-input-3`) as HTMLInputElement;
        lastInput?.focus();
      }, 0);
    }
  };

  const submitForm = async (event: React.FormEvent<HTMLButtonElement>) => {
    debugger
    event.preventDefault();
  
    if (otp.includes("")) {
      setOtpError(true);
      return;
    } else {
      setOtpError(false);
    }
  
    // Join the otp array into a single string
    const otpString = otp.join("");
  
    try {
      const response = await loginUser(
        "01",
        formData?.email,
        "",
        "EMAIL",
        otpString,
        "U3"
      );

      if (response.status === 200) {
        // console.log(response);
        setOtpError(false);
        setToken(response?.data?.data?.token);
        toast.success("Login Successfully");

      if(account === false){

        const callbackUrl = '/dashboard';
        
        setTimeout(() => {
          window.location.href = callbackUrl;    
        }, 1000);
      }


       
      } else {
        setOtpError(true);
        toast.error("Invalid OTP")
      }
    } catch (error) {
      // console.log("Login error:", error);
      setOtpError(true);
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent default behavior to avoid unintended submissions
      submitForm(e);
    }
  };

  function handleSubmit(e: React.KeyboardEvent<HTMLButtonElement>) {
    throw new Error("Function not implemented.");
  }

  // useEffect(() => {
  //   const value = sessionStorage.getItem('datas');
  //   if (value) {
  //     try {
  //       const parsedValue = JSON.parse(value);
  //       console.log(parsedValue); // Check what is being parsed
      
  //       createPartner(parsedValue)
  //     } catch (error) {
  //       console.log("Error parsing sessionStorage data:", error);
  //     }
  //   }
  // }, [account]);


  // async function createPartner(parsedValue) {
  //   if (parsedValue) {
  //     debugger;
  //     const firstName = parsedValue?.username;
  //     const emailval = parsedValue?.email;
  //     const phoneNumber = parsedValue?.phone; // Corrected from phonee
  //     const state =  parsedValue?.state;
  //     const country = parsedValue?.country;
  //     createPromoterClient(
  //       firstName,
  //       state,
  //       country,
  //       emailval,
  //       phoneNumber
  //     ).then((response) => {
  //       if (response.status === 200) {
  //         // router.push("/dashboard");
  //         const callbackUrl = '/dashboard';
        
  //         setTimeout(() => {
  //           window.location.href = callbackUrl;    
  //         }, 1000);
       
  //       } else if (response.status === 202) {
  //       } else {
  //         // Handle error
  //       }
  //     });
  //   }
  // }

  return (
    <>
                      <ToastContainer/>

   
    <Modal isOpen={isOpen} toggle={toggle} className={`mt-5 ${styles.modal}`} onKeyDown={handleKeyDown}>
      <ModalHeader toggle={toggle}>OTP Verification</ModalHeader>
      <ModalBody>
        <div className={styles["otp-inputs"]}>
          {otp.map((digit, index) => (
            <div className="m-2" key={index}>
              <Input
                type="text"
                id={`otp-input-${index}`}
                 autoComplete="off"
                maxLength={1}
                value={digit}
                onChange={(e) => handleChange(e, index)}
                onPaste={index === 0 ? handlePaste : undefined}

                className={`${styles["otp-input"]} ${otpError ? styles.invalid : ''}`}
              />
            </div>
          ))}
        </div>
        {/* {otpError && <p className={styles.errorText}>Please enter the full OTP.</p>} */}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>Close</Button>
        <Button color="primary" onClick={submitForm}  
      >Verify</Button>
      </ModalFooter>
    </Modal>
    </>
  );
};

export default LoginOTPModal;

import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input } from "reactstrap";
import styles from './email-modal.module.scss';
import { setCookie, setToken } from "assistance/user_service";
import { loginUser } from "assistance/identity_service";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { UpdateData, VerifyOTP } from "assistance/promoterClient_service";

interface OTPModalProps {
  isOpen: boolean;
  toggle: () => void;
  setdata:React.Dispatch<React.SetStateAction<boolean>>;
  data: boolean;
}

const VerifyEmailOTP: React.FC<OTPModalProps> = ({ isOpen, toggle,setdata,data }) => {
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [otpError, setOtpError] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const value = e.target.value;
    if (/^[0-9]?$/.test(value)) { // Allow only single digits
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move to the next input field or go back on delete
      if (value && index < otp.length - 1) {
        (document.getElementById(`otp-input-${index + 1}`) as HTMLInputElement)?.focus();
      } else if (!value && index > 0) {
        (document.getElementById(`otp-input-${index - 1}`) as HTMLInputElement)?.focus();
      }
    }
  };

  const submitForm = async (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
  
    if (otp.includes("")) {
      setOtpError(true);
      return;
    } else {
      setOtpError(false);
    }
  const data=sessionStorage.getItem("verifyEmail")
    // Join the otp array into a single string
    const otpString = otp.join("");
    const payload = {
      email:data,
      identityType:"EMAIL",
      type: "BUSINESSUSER",
      
    };
    try {
      const response = await UpdateData(
 
        payload?.email ?? '',
        payload?.identityType,
        payload?.type,
        otpString,
      );

      if (response.status === 200) {
        setOtpError(false);
        toast.success(" Successfully ");
        toggle()
        setdata(!data)
      } else {
        setOtpError(true);
      }
    } catch (error) {
      console.error("Login error:", error);
      setOtpError(true);
    }
  };


  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pasteData = e.clipboardData.getData("Text").trim();
    
    if (/^\d{4}$/.test(pasteData)) { // Check if the pasted data is exactly 4 digits
      const newOtp = pasteData.split("").slice(0, 4); // Split into an array, ensure only 4 digits
      setOtp(newOtp);
      setOtpError(false);
      e.preventDefault(); // Prevent the default paste action
  
      // Automatically move focus to the last input field
      setTimeout(() => {
        const lastInput = document.getElementById(`otp-input-3`) as HTMLInputElement;
        lastInput?.focus();
      }, 0);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent default behavior to avoid unintended submissions
      submitForm(e);
    }
  };


  return (

    <>
                          <ToastContainer/>

  
    <Modal isOpen={isOpen} toggle={toggle} className={`mt-5 ${styles.modal}`} onKeyDown={handleKeyDown}>
      <ModalHeader toggle={toggle}>OTP Verification</ModalHeader>
      <ModalBody>
        <div className={styles["otp-inputs"]}>
          {otp.map((digit, index) => (
            <div className="m-2" key={index}>
              <Input
                type="text"
                id={`otp-input-${index}`}
                 autoComplete="off"
                maxLength={1}
                value={digit}
                onChange={(e) => handleChange(e, index)}
                onPaste={index === 0 ? handlePaste : undefined}
                className={`${styles["otp-input"]} ${otpError ? styles.invalid : ''}`}
              />
            </div>
          ))}
        </div>
        {otpError && <p className={styles.errorText}>Please enter the full OTP.</p>}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>Close</Button>
        <Button color="primary" onClick={submitForm}>Verify</Button>
      </ModalFooter>
    </Modal>
    </>
  );
};

export default VerifyEmailOTP;

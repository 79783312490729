import Avatar from 'react-avatar'; // Import Avatar from 'react-avatar'
import styles from './company-profile-image.module.scss';

interface CompanyProfileImageProps {
  name: string; // Name or initials to display in the avatar
  type: string; // Type to determine the avatar style, e.g., 'user-profile' or others
}

const CompanyProfileImage: React.FC<CompanyProfileImageProps> = ({ name, type }) => {
  return (
    <Avatar
      name={name} // User's name or initials
      size="100" // Size of the avatar
      round={true} // Circular avatar
      className={type !== 'user-profile' ? styles['avatar-style'] : styles['user-pic']} // Custom CSS class based on type
      
    />
  );
};

export default CompanyProfileImage;
